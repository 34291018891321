import React, { createContext, useEffect, useState } from "react";
import Cookies from "js-cookie";
import { launchCleanExpiredCodePromoUsedTask, launchCleanExpiredOrder } from "../api/api";

export const CleanOrderAndCodePromoContext = createContext();

const CleanOrderAndCodePromoProvider = ({ children }) => {

  useEffect(() => {
    const fetchAndStoreLocation = async () => {
      const currentURL = window.location.href;
      const discount_using_code = getCodePromoFromCookies();
      if (discount_using_code) {
        if (!currentURL.includes("/fr/checkout/") && !currentURL.endsWith("/confirmation")) {
          try {
            await launchCleanExpiredCodePromoUsedTask(discount_using_code)
          } catch (error) {
            console.log(error)
          }
        }

        RemoveToCookies(discount_using_code)
      }
      const billId = getBillIdCookies();
      console.log(billId)
      
      if (billId && !currentURL.includes("/fr/checkout/") && !currentURL.endsWith("/confirmation")) {
        try {
          await launchCleanExpiredOrder(billId)
        } catch (error) {
          console.log(error)
        }
        RemoveBillIdToCookies(discount_using_code)
      }
    };
    fetchAndStoreLocation();
  }, []);
  ;

  const getCodePromoFromCookies = () => {
    const discount_using_code = Cookies.get("discount_using_code");
    return discount_using_code ? discount_using_code : null;
  };

  const RemoveToCookies = (discount_using_code) => {
    if (discount_using_code) {
      Cookies.remove("discount_code");
      Cookies.remove("discount_using_code");
    }
  };

  const getBillIdCookies = () => {
    const billId = Cookies.get("billId");
    return billId ? billId : null;
  };

  const RemoveBillIdToCookies = (billId) => {
    if (billId) {
      Cookies.remove("billId");
    }
  };


  return (
    <CleanOrderAndCodePromoContext.Provider>
      {children}
    </CleanOrderAndCodePromoContext.Provider>
  );
};

export default CleanOrderAndCodePromoProvider;