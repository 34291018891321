import {
  Box,
  Button,
  Card,
  CardMedia,
  Grid,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import Iconify from "../Iconify";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { useState } from "react";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase.config";
import PdfTickets from "../pdf/pdfTickets";
import { format, isSameDay } from "date-fns";
import { fr } from "date-fns/locale";
var QRCode = require("qrcode");

// ----------------------------------------------------------------------

const styleModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 400,
  bgcolor: "white",
  border: "1px solid white",
  borderRadius: "7px",
  boxShadow: 0,
  p: 3,
};

export default function Ticket({ img, info }) {

  const [qrcodes, setQrcodes] = useState([]);
  const [data, setData] = useState();
  const [bill, setBill] = useState();

  // Modal download liste participants
  const [openDownloadModal, setOpenDownloadModal] = useState(false);
  const handleOpenDownloadModal = () => setOpenDownloadModal(true);
  const handleCloseDownloadModal = () => setOpenDownloadModal(false);

  const handleTimestamp = (e) => {
    if (e) {
      const date = e.toDate();
      const tmp = date.toJSON().split(".");
      return format((new Date(date.toJSON())), 'yyyy-MM-dd HH:mm', { locale: fr }).toString();
    }
  
  };
  const formatSeanceDate = (dateDebutTimestamp, dateFinTimestamp) => {
    const dateDebut = new Date(handleTimestamp(dateDebutTimestamp));
    const dateFin = new Date(handleTimestamp(dateFinTimestamp));
  
    if (isSameDay(dateDebut, dateFin)) {
      // même jour : lun. 02 fév. 2025 20h45 - 23h45
      return `${format(dateDebut, 'EEE dd MMM yyyy HH', { locale: fr })}h${format(dateDebut, 'mm', { locale: fr })} - ${format(dateFin, 'HH', { locale: fr })}h${format(dateFin, 'mm', { locale: fr })}`;
    } else {
      // jours différents : lun. 02 fév. 2025 23h45 - mar. 03 fév. 2025 04h45
      return `${format(dateDebut, 'EEE dd MMM yyyy HH', { locale: fr })}h${format(dateDebut, 'mm', { locale: fr })} - ${format(dateFin, 'EEE dd MMM yyyy HH', { locale: fr })}h${format(dateFin, 'mm', { locale: fr })}`;
    }
  };

  const downloadTicket = async () => {
    // generation des qr code
    try {
      // commande 
      // billet
      console.log('/////////////////////')
      console.log(info.commande.data())
      const tmpbillet = info.commande.data().billets
      const tmpTicketInfo = []
      console.log(info.commande.data().billets)
      for (let index = 0; index < tmpbillet.length; index++) {
        const element = tmpbillet[index].billetID;
        const ticketSnap = await getDoc(
          doc(db, `evenement/${info.event.id}/billets/`, element)
        );
        
        QRCode.toDataURL(
          element,
          {
            width: 130,
            margin: 1,
            errorCorrectionLevel: "H",
            color: {
              dark: "#000000",
              light: "#ffffff",
            },
          },
          (err, url) => {
            if (err) return console.log("erreur : ", err);
            setQrcodes((qrcodes) => [...qrcodes, url]);
            tmpTicketInfo.push({ qr: url, price: ticketSnap.data().price, name: ticketSnap.data().nom, numero: element })
            // setQr(url);
          }
        );
      }

      // recuperation de la facture
      const refBillSnap = await getDoc(
        doc(db, "bills", info.commande.data().idFacture)
      );
      setBill(refBillSnap.data())
      console.log(refBillSnap.data())
      // recuperation org 
      const orgSnap = await getDoc(
        doc(db, `organisations/`, info.event.data().orgId)
      );

      // recuperation seance
      let seance = null 
      if (info.event.data().seanceActive === 1) {
        seance = await getDoc(
          doc(db, `evenement/${info.event.id}/seances/`, info.commande.data().seance)
        );
      }
       
      setData({ event: info.event.data(), tickets: tmpTicketInfo, order: info.commande.data(), bill: refBillSnap.data(), org: orgSnap.data(), seance: info.event.data().seanceActive === 1 ? seance.data() : null })
      handleOpenDownloadModal();
    } catch (error) {
      console.log(error);
    }

    // handleCloseDownloadModal(); 
  };

  return (
    <Card sx={{ marginY: 2 }}>
      <Grid padding={2} container spacing={2}>
        <Grid item xs={12} sm={4}>
          <Card sx={{ maxWidth: 500 }}>
            <CardMedia
              sx={{ height: 140 }}
              image={info.event.data().idVisuel}
              title="green iguana"
            />
          </Card>
        </Grid>
        <Grid item xs={12} sm={8} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <Typography
            sx={{
              fontSize: "16px",
            }}
          >
            <strong>{info?.event?.data().name ? info?.event?.data().name : info?.event?.data().nomEvent}</strong>
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              color: "#5E17EB"
            }}
          >
            {info?.event && formatSeanceDate(info?.event.data().dateDebut, info?.event.data().dateFin)}
          </Typography>
          <Typography
            sx={{
              fontSize: "15px",
              fontWeight: "500",
            }}
          >
            {info.event.data().localisationDes ? info.event.data().localisationDes : `${info.event.data().lieu_event}, ${info.event.data().city}, ${info.event.data().country}`}
          </Typography>
          <Typography
            sx={{
              fontSize: "15px",
              color: "#7A7A7A",
              fontWeight: "500",
              mb: 3,
            }}
          >
            Commande N° {info?.commande?.id}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Button
            fullWidth
            size="large"
            sx={{
              textTransform: "initial",
              border: "2px solid #5E17EB",
              backgroundColor: "#5E17EB",
              color: "white",
              borderRadius: "7px",
              boxShadow: 'none',
              "&:hover": {
                backgroundColor: "#5E17EB",
                border: "2px solid #5E17EB",
                color: "white",
              },
            }}
            variant="contained"
            onClick={() => downloadTicket()}
          >
            <Stack
              spacing={1}
              direction="row"
              display="flex"
              alignItems="center"
            >
              <Iconify
                icon="material-symbols:download"
                sx={{ height: 24, width: 24 }}
              />
              <Typography fontSize={16}>Télécharger {info?.commande?.data()?.billets.length} billet(s)</Typography>{" "}
            </Stack>
          </Button>
          <Modal
            open={openDownloadModal}
            onClose={handleCloseDownloadModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{ borderRadius: "7px" }}
          >
            <Box sx={styleModal}>
              <Typography variant="body2">
                Cliquez{" "}
                <PDFDownloadLink
                  document={<PdfTickets data={data}/>}
                  fileName={`${bill ? bill.refCommande : "undefined"
                    }.pdf`}
                >
                  {({ blob, url, loading, error }) => {
                    console.log("erreur : ", error);
                    return loading ? "Genration du ticket......" : "ici";
                  }}
                </PDFDownloadLink>{" "}
                pour télécharger le ticket
              </Typography>
            </Box>
          </Modal>
        </Grid>
      </Grid>
    </Card>
  );
}
