import {
  Box,
  Container,
  Grid,
  MobileStepper,
  Skeleton,
  Stack,
  styled,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useContext } from "react";
import House from "./EventCard";
import { properties } from "../properties";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { db } from "../firebase.config";
import EventCard from "./EventCard";
import Iconify from "./Iconify";
import useResponsive from "./hooks/useResponsive";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import Cookies from "js-cookie";
import { LocationContext } from "./service/UserLocation";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
const Properties = () => {
  const PropertiesBox = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "space-between",
    marginTop: theme.spacing(5),
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "center",
    },
  }));

  const PropertiesTextBox = styled(Box)(({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
    },
  }));
  const smUp = useResponsive("up", "sm");
  const location = useContext(LocationContext);
  const mdUp = useResponsive("up", "md");
  const [loading, setLoading] = React.useState(false);
  const [events, setEvents] = React.useState([]);
  const [orgs, setOrgs] = React.useState([]);
  const createEventList = (doc) => {
    setEvents((events) => [...events, doc]); // ajouter a la fin
  };
  const [city, setCity] = React.useState("Abidjan");




  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = 3;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const getLocationFromCookies = () => {
    const location = Cookies.get("userLocation");
    return location ? JSON.parse(location) : null;
  };

  React.useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const today = new Date();
        /* navigator.geolocation.getCurrentPosition(async (position) => {
          const { latitude, longitude } = position.coords;
          console.log(latitude);

          const reponse = await fetch(
            `https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/reverseGeocode?f=pjson&featureTypes=&location=${longitude},${latitude}`
          );
          const data = await reponse.json();
          console.log(data);
          setCity(data.address.City);
        }); */
        setLoading(true);

        const location = getLocationFromCookies();


        setCity(location.city);

        setEvents([]);

        const q = query(
          collection(db, "evenement"),
          where("status", "==", 1),
          where(
            "dateDebut",
            ">=",
            new Date(today.getFullYear(), today.getMonth(), today.getDate())
          ),

          where("city", "==", location.city),
          orderBy("dateDebut", "asc"),
          limit(3)
        );
        const querySnapshot = await getDocs(q);
        // querySnapshot.empty()
        // console.log(querySnapshot);
        // setEvents(querySnapshot.docs);
        querySnapshot.forEach(async (d) => {
          // get event organisateur

          const querySnapshot1 = await getDoc(
            doc(db, "organisations", d.data().orgId)
          );

          setEvents((events) => [
            ...events,
            { infos: d, org: querySnapshot1.data().nomOrg },
          ]);
        });
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    })();
  }, [location]);

  return (
    <Box sx={{ backgroundColor: "white", py: 15 }}>
      <Container>
        <PropertiesTextBox>
          <Typography
            align="left"
            sx={{ color: "#1F1F1F", fontSize: "33px", fontWeight: "800", textTransform: "uppercase" }}
          >
            événements POPULAIRES à{" "}
            <span style={{ color: "#5E17EB", textTransform: "uppercase" }}>
              {city}
            </span>
          </Typography>
        </PropertiesTextBox>

        <PropertiesBox>
          {loading ? (
            <Grid container spacing={5} paddingY={2}>
              <Grid item xs={12} md={4}>
                <Skeleton variant="rectangular" height={250} />
                <Box sx={{ pt: 0.5 }}>
                  <Skeleton />
                  <Skeleton width="60%" />
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Skeleton variant="rectangular" height={250} />
                <Box sx={{ pt: 0.5 }}>
                  <Skeleton />
                  <Skeleton width="60%" />
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Skeleton variant="rectangular" height={250} />
                <Box sx={{ pt: 0.5 }}>
                  <Skeleton />
                  <Skeleton width="60%" />
                </Box>
              </Grid>
            </Grid>
          ) : smUp ? (
            <Grid container rowSpacing={5} paddingY={2}>
              {events.length === 0 && loading === false && (

                <Stack
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width={"100%"}
                  spacing={1}
                >
                  <Typography variant="h6">Oups c'est calme pour le moment</Typography>
                  <Typography variant="body1" textAlign='center'>Il semble que la fête soit terminée pour aujourd'hui. Restez a l'âffut pour lesprochains événements</Typography>

                </Stack>
              )}
              {events.length !== 0 &&
                events.map((event, index) => (
                  <Grid item xs={12} md={4} sx={{ height: `100%` }}>
                    <EventCard
                      key={event.id}
                      img={event.infos.data().idVisuel}
                      name={event.infos.data().nomEvent}
                      lieu={event.infos.data().lieu_event}
                      city={event.infos.data().city}
                      type={event.infos.data().type}
                      debut={event.infos.data().dateDebut}
                      organisateur={event.org}
                      eventID={event.infos.id}
                      sx={{ height: `100%` }}
                    />
                  </Grid>
                ))}
            </Grid>
          ) : (
            <Box sx={{ width: '100%', flexGrow: 1 }}>
              {events.length === 0 && loading === false && (
                <Stack
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width={"100%"}
                  spacing={1}
                >
                  <Typography variant="h6">Oups c'est calme pour le moment</Typography>
                  <Typography variant="body1" textAlign='center'>Il semble que la fête soit terminée pour aujourd'hui. Restez a l'âffut pour lesprochains événements</Typography>

                </Stack>
              )}
              <AutoPlaySwipeableViews
                axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                index={activeStep}
                onChangeIndex={handleStepChange}
                enableMouseEvents
              >
                {events.map((event, index) => (
                  <Box paddingX={2} >
                    <EventCard
                      key={event.id}
                      img={event.infos.data().idVisuel}
                      name={event.infos.data().nomEvent}
                      lieu={event.infos.data().lieu_event}
                      city={event.infos.data().city}
                      type={event.infos.data().type}
                      debut={event.infos.data().dateDebut}
                      organisateur={event.org}
                      eventID={event.infos.id}
                      sx={{ height: `100%` }}
                    />
                  </Box>

                ))}
              </AutoPlaySwipeableViews>
              <MobileStepper
                sx={{ color: '#1F1F1F', backgroundColor: 'transparent', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                steps={maxSteps}
                position="static"
                activeStep={activeStep}
              />
            </Box>
          )}
        </PropertiesBox>
      </Container>
    </Box>
  );
};

export default Properties;
