import { Document, Image, Page, View, Text } from "@react-pdf/renderer";
import QRCodeComponent from "./Qrcode";
import { format, isSameDay } from "date-fns";
import { fr } from "date-fns/locale";


const styles = {
  page: {
    backgroundColor: "#fff",
    fontFamily: "Helvetica",
    lineHeight: 1.5,
    flexDirection: "column",
  },
  logo: {
    width: 50,
    height: 50,
    marginLeft: "auto",
    marginRight: "auto",
  },
  imageContainer: {
    backgroundColor: "#5E17EB",
    width: "100%",
    height: "40%",
  },
  image: {
    backgroundColor: "#5E17EB",
    width: "100%",
    height: "100%",
  },
  // eventpart
  eventBg: {
    backgroundColor: "#F2ECFD",
  },
  eventPart: {
    flexDirection: "row",
    marginTop: 15,
    marginBottom: 15,
    marginHorizontal: 20,
  },
  eventPart1: {
    width: "50%",
    textAlign: "left",
  },
  eventPart2: {
    width: "50%",
    textAlign: "left",
  },
  ticketPart: {
    flexDirection: "row",
    marginTop: 15,
    marginBottom: 15,
    marginHorizontal: 20,
  },
  ticketPart1: {
    width: "50%",
    textAlign: "left",
  },
  ticketPart2: {
    width: "50%",
    textAlign: "center",
  },
  textHead: {
    fontSize: 12,
    fontWeight: "bold",
  },
  textEventname: {
    fontSize: 15,
    fontWeight: "heavy",
  },
  textSecondary: {
    fontSize: 10,
    fontWeight: "bold",
    color: '#525252'
  },
  textImportant: {
    fontSize: 13,
    fontWeight: 900,
  },
  text: {
    fontSize: 10,
  },
  textBold: {
    fontSize: 10,
    fontWeight: "bold",
  },
  textDetails: {
    fontSize: 7,
  },
  qrCodeContainer: {

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 20,

  },
  qrCode: {
    height: 130,
    width: 130,
  },
  detailContain: {
    marginTop: 15,
    padding: 5,
    backgroundColor: "#F3EDFD",
    borderRaduis: "10px",
    width: "100%",
  },
};

const handleTimestamp = (e) => {
  if (e) {
    const date = e.toDate();
    const tmp = date.toJSON().split(".");
    return format((new Date(date.toJSON())), 'yyyy-MM-dd HH:mm', { locale: fr }).toString();
  }

};

const formatSeanceDate = (dateDebutTimestamp, dateFinTimestamp) => {
  const dateDebut = new Date(handleTimestamp(dateDebutTimestamp));
  const dateFin = new Date(handleTimestamp(dateFinTimestamp));

  if (isSameDay(dateDebut, dateFin)) {
    // même jour : lun. 02 fév. 2025 20h45 - 23h45
    return `${format(dateDebut, 'EEE dd MMM yyyy HH', { locale: fr })}h${format(dateDebut, 'mm', { locale: fr })} - ${format(dateFin, 'HH', { locale: fr })}h${format(dateFin, 'mm', { locale: fr })}`;
  } else {
    // jours différents : lun. 02 fév. 2025 23h45 - mar. 03 fév. 2025 04h45
    return `${format(dateDebut, 'EEE dd MMM yyyy HH', { locale: fr })}h${format(dateDebut, 'mm', { locale: fr })} - ${format(dateFin, 'EEE dd MMM yyyy HH', { locale: fr })}h${format(dateFin, 'mm', { locale: fr })}`;
  }
};

const PdfTickets = ({data }) => {
  return (
    <Document>
      {
        data.tickets.map((qr, index) => (
          <Page key={index} size="A4" style={styles.page}>
            <View style={styles.imageContainer}>
              <Image style={styles.image} src={data.event.idVisuel} />
            </View>

            <View style={styles.eventBg}>
              <View style={styles.eventPart}>
                <View style={styles.eventPart1}>
                  <View>
                    <Text style={{ fontWeight: 900, fontSize: 15 }}>
                      {" "}
                      {data.event.nomEvent}
                    </Text>
                    {
                      data.event.seanceActive === 1 ? <Text break style={styles.text}>
                        {" "}
                        {data.seance.name !== null && data.seance.name !== '' ? data.seance.name : '-'}
                      </Text> : null
                    }

                    <Text break style={styles.textSecondary}>
                      {" "}
                      Par Organisateur
                    </Text>
                    <Text break style={styles.text}>
                      {" "}
                      {data.org.nomOrg}
                    </Text>
                  </View>
                </View>
                <View style={styles.eventPart2}>
                  <Text style={styles.textSecondary}>Date</Text>
                  <Text break style={styles.text}>
                  {data.event && data.event.seanceActive === 0 ? formatSeanceDate(data.event.dateDebut, data.event.dateFin) : formatSeanceDate(data.seance.date, data.seance.fin) }
                  </Text>
                  <Text style={styles.textSecondary}>Adresse</Text>
                  <Text break style={styles.text}>
                    {" "}
                    {data.event.localisationDes ? data.event.localisationDes : `${data.event.lieu_event} ${data.event.city}, ${data.event.country}`}
                  </Text>
                </View>
              </View>
            </View>
            <View>
              <View style={styles.ticketPart}>
                <View style={styles.ticketPart1}>
                  <Text style={styles.textHead}> {data.tickets[index].name}</Text>
                  <View>
                    <Text break style={styles.textSecondary}>
                      {" "}
                      Commande N° {data.bill.refCommande}{" "}
                    </Text>
                    <Text style={styles.textBold}>
                      {" "}
                      Billet : {data.tickets[index].numero} - Prix : {data.tickets[index].price} CFA
                    </Text>
                    <Text break style={styles.textSecondary}>
                      {" "}
                      Client
                    </Text>
                    <Text break style={styles.text}>
                      {" "}
                      {data.order.nom} - {data.order.email}
                    </Text>
                  </View>
                </View>
                <View style={styles.ticketPart2}>
                  <View style={styles.qrCodeContainer}>
                    <Text break style={styles.text}>
                      {" "}
                      {index + 1}/{data.tickets.length}
                    </Text>
                    <Image
                      style={styles.qrCode}
                      src={data.tickets[index].qr} />
                    <Text break style={styles.text}>
                      {" "}
                      {data.tickets[index].numero}
                    </Text>
                  </View>

                  <View style={styles.detailContain}>
                    <Text style={styles.text}>
                      {" "}
                      Modalite et condition de revent
                    </Text>
                    <Text break style={styles.textDetails}>
                      Tout billet revendu sur une plateforme de revente autre que
                      l’application ChoptonTicket n’est pas sécurisé et ne garantit
                      pas l’entrée à l’évènement.
                    </Text>
                  </View>
                </View>
              </View>
            </View>


          </Page>
        ))
      }
    </Document>
  );
};

export default PdfTickets;
