import React, { useEffect, useRef, useState } from 'react';
import { PDFDownloadLink, Document, Page, Text } from '@react-pdf/renderer';
import { useParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase.config';
import PdfTickets from '../Components/pdf/pdfTickets';
import { Box, CircularProgress } from '@mui/material';
var QRCode = require("qrcode");

// Composant PDF simple
const MyDocument = () => (
    <Document>
        <Page>
            <Text>Voici votre PDF téléchargé automatiquement !</Text>
        </Page>
    </Document>
);

const AutoDownloadPdf = () => {
    const { downloadID } = useParams();
    const [data, setData] = useState();
    const [bill, setBill] = useState();
    const [loading, setLoading] = useState(true);
    const downloadLinkRef = useRef(null);

    React.useEffect(() => {
        (async () => {
            setLoading(true)

            if (downloadID) {
                try {
                    // recuperation du code de telechargment
                    const refDoc = await getDoc(
                        doc(db, `downloadTicket/`, downloadID)
                    );
                    if (refDoc.exists()) {
                        // recuperation de la de l'event
                        const event = await getDoc(
                            doc(db, `evenement/`, refDoc.data().eventID)
                        );
                        // recuperation de la commande
                        const commande = await getDoc(
                            doc(db, `evenement/${event.id}/commandes/`, refDoc.data().commandeID)
                        );
                        console.log('/////////////////////')
                        console.log(commande.data())
                        const tmpbillet = commande.data().billets
                        const tmpTicketInfo = []
                        console.log(commande.data().billets)
                        for (let index = 0; index < tmpbillet.length; index++) {
                            const element = tmpbillet[index];
                            const ticketSnap = await getDoc(
                                doc(db, `evenement/${event.id}/billets/`, element)
                            );

                            QRCode.toDataURL(
                                element,
                                {
                                    width: 130,
                                    margin: 1,
                                    errorCorrectionLevel: "H",
                                    color: {
                                        dark: "#000000",
                                        light: "#ffffff",
                                    },
                                },
                                (err, url) => {
                                    if (err) return console.log("erreur : ", err);
                                    tmpTicketInfo.push({ qr: url, price: ticketSnap.data().price, name: ticketSnap.data().nom, numero: element })
                                    // setQr(url);
                                }
                            );
                        }

                        // recuperation de la facture
                        const refBillSnap = await getDoc(
                            doc(db, "bills", commande.data().idFacture)
                        );
                        setBill(refBillSnap.data())
                        console.log(refBillSnap.data())
                        // recuperation org 
                        const orgSnap = await getDoc(
                            doc(db, `organisations/`, event.data().orgId)
                        );

                        // recuperation seance
                        let seance = null
                        if (event.data().seanceActive === 1) {
                            seance = await getDoc(
                                doc(db, `evenement/${event.id}/seances/`, commande.data().seance)
                            );
                        }

                        setData({ event: event.data(), tickets: tmpTicketInfo, order: commande.data(), bill: refBillSnap.data(), org: orgSnap.data(), seance: event.data().seanceActive === 1 ? seance.data() : null })
                        setLoading(false)
                        console.log('je suis sur le point de cliquer')
                        // Déclenche le clic automatiquement après un délai court
                        // if (downloadLinkRef.current) {
                            console.log('je vais cliquer')
                            setTimeout(() => {
                                downloadLinkRef.current.click();
                            }, 1000); // délai d'une seconde pour garantir le rendu
                        // }

                    }

                    // handleOpenDownloadModal();
                } catch (error) {
                    setLoading(false)
                    console.log(error);
                }
            }


        })();
    }, []);



    return (
        <div>
            {
                loading ?
                    <CircularProgress />
                    :
                    <Box>
                        <PDFDownloadLink
                            document={<PdfTickets data={data} />}
                            fileName={`${bill ? bill.refCommande : "undefined"
                                }.pdf`}
                        >
                            {({ loading }) => (
                                <button ref={downloadLinkRef} style={{ display: 'none' }}>
                                    {loading ? 'Chargement du document...' : 'Télécharger'}
                                </button>
                            )}
                        </PDFDownloadLink>{" "}
                        <p>Votre téléchargement démarrera automatiquement...</p>
                    </Box>
            }

        </div>
    );
};

export default AutoDownloadPdf;
