import {
  Box,
  Button,
  Card,
  CardMedia,
  Checkbox,
  CircularProgress,
  Container,
  Divider,
  FormControlLabel,
  Grid,
  Link,
  Radio,
  RadioGroup,
  Skeleton,
  Stack,
  styled,
  TextField,
  Typography,
  IconButton,
} from "@mui/material";
import {
  addDoc,
  collection,
  doc,
  Firestore,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
} from "firebase/firestore";
import React, { useEffect } from "react";
import { db, functions } from "../../firebase.config";
import CustomButton from "../CustomButton";
import EventCard from "../EventCard";
import Iconify from "../Iconify";
import { PayWithPayD } from "../paiement/PayWithPaydunya";
import { useParams } from "react-router-dom";
import Timer from "./Timer";
import { UserAuth } from "../../context/AuthContext";
import Modal from "@mui/material/Modal/Modal";
import Backdrop from "@mui/material/Backdrop/Backdrop";
import Fade from "@mui/material/Fade/Fade";
import { httpsCallable } from "firebase/functions";
import { Sheet } from 'react-modal-sheet';
import { checkPromoCode } from "../api/api";
import { shortNumber } from "../util/formatNumber";
import Cookies from "js-cookie";


var paydunya = require("paydunya");

/*function payWithPaydunya(btn) {
  payWithPaydunya
    .setup({
      selector: btn,
      url: "https://choptonticket/paydunya-api",
      method: "GET",
      displayMode: payWithPaydunya.DISPLAY_IN_POPUP,
      beforeRequest: function () {
        console.log("About to get a token and the url");
      },
      onSuccess: function (token) {
        console.log("Token: " + token);
      },
      onTerminate: function (ref, token, status) {
        console.log(ref);
        console.log(token);
        console.log(status);
      },
      onError: function (error) {
        alert("Unknown Error ==> ", error.toString());
      },
      onUnsuccessfulResponse: function (jsonResponse) {
        console.log("Unsuccessful response ==> " + jsonResponse);
      },
      onClose: function () {
        console.log("Close");
      },
    })
    .requestToken();
}*/

var setup = new paydunya.Setup({
  masterKey: "DNrUjYFV-6HiQ-NNu5-DAgU-WizSswjzZW6I",
  privateKey: "test_private_Qyqe0MaokWBMmU1m5d1kNHcj7fs",
  publicKey: "test_public_8FAyuNRDgwV2v8Yujc8QHvlYtuS",
  token: "vT8PF5dQR3zx7r9OcplJ",
  mode: "test",
  cancelURL: "http://localhost:5000/",
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};
const styleRecap = {
  position: "absolute",
  top: "0",
  left: "0",
  width: "100%",
  height: "100%",
  bgcolor: "background.paper",
  p: 2,
};

export default function ReserMobile2({ event, montant, ticketsList, seance, widget }) {
  const { user } = UserAuth();
  const { eventID } = useParams();
  const [loading, setLoading] = React.useState(false);
  const [loadingF, setLoadingF] = React.useState(false);
  const [total, setTotal] = React.useState(montant);
  const [check, setCheck] = React.useState(false);
  const [nomField, setNom] = React.useState("");
  const [prenomField, setPrenom] = React.useState("");
  const [emailField, setEmail] = React.useState("");
  const [confirmEmailField, setConfirmEmail] = React.useState("");
  const [discountField, setDiscount] = React.useState("");
  const [reduction, setReduction] = React.useState();
  const [addCode, setAddCode] = React.useState(false);
  const [codeCheck, setCodeCheck] = React.useState({});
  const [fieldError, setError] = React.useState(false);
  const [confirmError, setConfirmError] = React.useState(false);
  const [fee, setFee] = React.useState(0);
  const [moyenPaie, setMoyenpaie] = React.useState(0);

  const handleRadioChange = (event) => {
    var tmp = event.target.value;
    if (parseInt(tmp) === 0) {
      setFee(0)
    } else if (parseInt(tmp) === 1) {
      setFee(1.5)
    } else {
      setFee(2.5)
    }
    setMoyenpaie(parseInt(event.target.value));
  };

  useEffect(() => {
    (async () => {
      try {
        setLoadingF(true);
        const docRef0 = doc(db, "users", user.uid);
        const docSnap = await getDoc(docRef0);
        if (docSnap.exists()) {
          setEmail(docSnap.data().email);
          setNom(docSnap.data().nom);
          setPrenom(docSnap.data().prenom);
          setConfirmEmail(docSnap.data().email);
          console.log("start");
          // setLoading(false);
        } else {
          const docRef0 = doc(db, "organisateurs", user.uid);
          const docSnap = await getDoc(docRef0);
          if (docSnap.exists()) {
            setEmail(docSnap.data().email);
            setConfirmEmail(docSnap.data().email);
            setNom(docSnap.data().nom);
            setPrenom(docSnap.data().prenom);

            // setLoading(false);
          }
        }
      } catch (error) {
        console.log(error);
      }
      setLoadingF(false);
    })();
  }, [user]);

  //Creation de la facture
  //fonction de paiement
  function getCookie(name) {
    const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
    if (match) return match[2];
    return null;
  }
  const getLocationFromCookies = () => {
    const location = Cookies.get("userLocation");
    return location ? JSON.parse(location) : null;
  };
  //fonction de paiement
  const pay = async () => {
    setLoading(true);
    var montant = 0;
    if (nomField === "" || prenomField === "" || emailField === "") {
      setError(true);
      setLoading(false);
      return;
    }
    if (emailField !== confirmEmailField) {
      setConfirmError(true);
      setLoading(false);
      return;
    }

    console.log(seance)
    const affiliate = getCookie(`affId-${eventID}`);
    // const source = getCookie(`source-${eventID}`);
    const location = getLocationFromCookies();
    const source = 'Direct'
    try {
      const addMessage = httpsCallable(functions, "initiatePay");
      const result = await addMessage({ tickets: ticketsList, uid: user ? user.uid : null, firstname: prenomField, lastname: nomField, mobile: '', email: emailField, eventid: eventID, from: 1, discount: reduction, fee: fee, seance: seance, track: affiliate ? { aff: affiliate } : null, location: location, source: source });
      console.log("-----------------result payment----------------")
      console.log(result.data)
      if (result.data?.status === 'ok') {
        const url = result.data.link; // "/fr/event/12345"
        const parts = url.split("/"); // ["", "fr", "event", "12345"]
        const billId = parts[parts.length - 1];
        console.log(billId)
        Cookies.set(`billId`, billId, { expires: 1 });
        window.location.href = result.data.link
      } else {
        setLoading(false);
        // window.location.replace(`http://localhost:3001/fr/event/${eventID}?order=unavalable`)
        window.location.replace(`http://choptonticket.com/fr/event/${eventID}?order=unavalable`)
        // navigate(`/fr/event/${eventID}?stateOrder=unavalable`)

      }
    } catch (error) {
      console.log(error)
      setLoading(false);
    }
  };

  const [procedCheckCP, setProcedCheckCP] = React.useState(false);
  const handleVerifyPromoCode = async () => {
    let response
    try {
      setLoading(true)
      setProcedCheckCP(true)
      response = await checkPromoCode(eventID, discountField, ticketsList);
      console.log(response.discount)
      setReduction(response.discount);
      setCodeCheck({ valid: true, message: response.message, })

    } catch (error) {
      setCodeCheck({ valid: false, message: 'code non valide ou expiré', })
    } finally {
      setLoading(false)
      setProcedCheckCP(false)
    }

  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  /** recapitulatif modal */
  const [openRecap, setOpenRecap] = React.useState(false);
  const handleOpenRecap = () => setOpenRecap(true);
  const handleCloseRecap = () => setOpenRecap(false);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {loadingF ? (
        <Stack paddingY={10}>
          <CircularProgress size={80} sx={{ color: "#5E17EB" }} />
        </Stack>
      ) : (
        <Box
          sx={{
            bgcolor: "white",
          }}
        >
          <Grid container>
            {/** info de la commande */}
            <Grid item xs={12} >
              <Grid container>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                {

                  <Grid
                    sx={{ overflowX: "auto", overflowY: "auto", height: 'calc(100vh - 150px)' }}
                    item
                    xs={12}
                    paddingX={1}
                    paddingBottom={20}
                    // maxHeight={800}
                    height={600}
                  >
                    <Typography
                      paddingY={2}
                      paddingBottom={2}
                      variant="h6"
                      fontWeight={500}
                    >
                      Informations de facturation
                    </Typography>
                    <Stack>
                      <Typography></Typography>
                    </Stack>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        {fieldError && nomField === "" ? (
                          <TextField
                            disabled={loading}
                            value={nomField}
                            onChange={(e) => setNom(e.target.value)}
                            fullWidth
                            label="Nom"
                            size="medium"
                            error
                            helperText="Merci de remplir ce champs."
                          />
                        ) : (
                          <TextField
                            disabled={loading}
                            value={nomField}
                            onChange={(e) => setNom(e.target.value)}
                            fullWidth
                            label="Nom"
                            size="medium"
                          />
                        )}
                      </Grid>
                      <Grid item xs={6}>
                        {fieldError && prenomField === "" ? (
                          <TextField
                            disabled={loading}
                            value={prenomField}
                            onChange={(e) => setPrenom(e.target.value)}
                            fullWidth
                            label="Prenom"
                            error
                            helperText="Merci de remplir ce champs."
                          />
                        ) : (
                          <TextField
                            disabled={loading}
                            value={prenomField}
                            onChange={(e) => setPrenom(e.target.value)}
                            fullWidth
                            label="Prenom"
                          />
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        {fieldError && emailField === "" ? (
                          <TextField
                            disabled={loading}
                            type="email"
                            value={emailField}
                            onChange={(e) => setEmail(e.target.value)}
                            fullWidth
                            label="Email"
                            error
                            helperText="Une adresse email est requise."
                          />
                        ) : (
                          <TextField
                            disabled={loading}
                            value={emailField}
                            onChange={(e) => setEmail(e.target.value)}
                            fullWidth
                            label="Email"
                          />
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        {fieldError && confirmEmailField === "" ? (
                          <TextField
                            disabled={loading}
                            value={confirmEmailField}
                            onChange={(e) => setConfirmEmail(e.target.value)}
                            fullWidth
                            label="Confirmer l'email "
                            error
                            helperText="Une adresse email est requise"
                          />
                        ) : (
                          <TextField
                            disabled={loading}
                            value={confirmEmailField}
                            onChange={(e) => setConfirmEmail(e.target.value)}
                            fullWidth
                            label="Confirmer l'email "
                            error={confirmError ? true : false}
                            helperText={
                              confirmError
                                ? "Les emails doivent être identiques."
                                : ""
                            }
                          />
                        )}
                      </Grid>
                    </Grid>

                    <Stack
                      sx={{ borderRadius: '16px', bgcolor: '#f2f2f2' }}
                      padding={2}
                      marginY={5}
                    >
                      {
                        addCode ?
                          <Stack spacing={1}
                            direction="row"
                            justifyContent="right" alignItems={'center'}>
                            {
                              codeCheck.valid ? <Typography sx={{ color: 'green' }} variant="caption">{codeCheck.message}</Typography> : !codeCheck.valid ? <Typography sx={{ color: 'red' }} variant="caption">{codeCheck.message}</Typography> : null
                            }
                            <TextField size="small" value={discountField}
                              onChange={(e) => setDiscount(e.target.value)} />

                            <CustomButton disabled={loading} size="small" type={'primaire'} buttonText={'appliquer'} onClick={handleVerifyPromoCode} />
                          </Stack>
                          :
                          <Stack spacing={1}
                            direction="row"
                            justifyContent="right" alignItems={'center'}>
                            <Typography variant="caption">Vous avez un code de réduction ?</Typography>
                            <Button
                              onClick={() => setAddCode(true)}
                              variant="text"
                              size="small"
                              sx={{
                                fontSize: 12,
                                textTransform: 'initial',
                                border: '2px solid transparent',
                                color: '#5E17EB',
                                backgroundColor: 'transparent',
                                borderRadius: '7px',
                                '&:hover': {
                                  backgroundColor: 'transparent',
                                  color: '#5E17EB',
                                  borderColor: 'transparent',
                                },
                              }}
                            >
                              cliquez-ici
                            </Button>
                          </Stack>

                      }

                    </Stack>
                    {reduction && (montant - reduction.value) === 0 ? <></> : montant === 0 ? <></> :
                      <>
                        <Typography variant="h6" fontWeight={500}>
                          Payer avec
                        </Typography>

                        <Box
                          marginTop={2}
                          marginBottom={5}
                        >
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            value={moyenPaie}
                            disabled={loading}
                            name="radio-buttons-group"
                            onChange={(e) => {
                              handleRadioChange(e);
                            }}
                          >
                            <Stack spacing={1}>
                              <Stack
                                direction={'row'}
                                sx={{ borderRadius: '16px', bgcolor: '#f2f2f2' }}
                                paddingY={2} paddingX={1}
                                alignItems={'center'}
                                justifyContent={'space-between'}
                              >
                                <Box sx={{ display: 'flex', alignItems: 'center' }} >
                                  <FormControlLabel label="Mobile Money & Wave" value={0} control={<Radio />} />
                                </Box>

                              </Stack>
                              <Stack
                                direction={'row'}
                                sx={{ borderRadius: '16px', bgcolor: '#f2f2f2' }}
                                paddingY={2} paddingX={1}
                                alignItems={'center'}
                                justifyContent={'space-between'}
                              >
                                <Box sx={{ display: 'flex', alignItems: 'center' }} >
                                  <FormControlLabel label="Carte de débit ou de crédit" value={1} control={<Radio />} />
                                </Box>
                                <Iconify
                                  icon="bi:credit-card-2-back"
                                  sx={{ width: 30, height: 30 }}
                                />

                              </Stack>
                              {/* <Grid
                                container
                                sx={{ borderRadius: '16px', bgcolor: '#f2f2f2' }}
                                paddingY={2} paddingX={1}
                              >
                                <Grid item xs={1} paddingX={1}>
                                  {" "}
                                  <FormControlLabel value={1} control={<Radio />} />
                                </Grid>
                                
                                <Grid item xs={10} display="center" alignItems="center">
                                  Carte de débit ou de crédit
                                </Grid>
                                <Grid item xs={1} display="center" alignItems="center">
                                  <Iconify
                                    icon="bi:credit-card-2-back"
                                    sx={{ width: 30, height: 30 }}
                                  />
                                </Grid>
                                {moyenPaie === 1 ? (
                                  <Grid container spacing={2} paddingY={1} paddingX={3}>
                                    <Grid item xs={12}>
                                      <TextField
                                        fullWidth
                                        label="Numéro de la carte"
                                        size="medium"
                                      />
                                    </Grid>
                                    <Grid item xs={8}>
                                      <TextField fullWidth label="Date d'experation" />
                                    </Grid>
                                    <Grid item xs={4}>
                                      <TextField fullWidth label="CVV" />
                                    </Grid>
                                  </Grid>
                                ) : null}
                              </Grid> */}
                              <Stack
                                direction={'row'}
                                sx={{ borderRadius: '16px', bgcolor: '#f2f2f2' }}
                                paddingY={2} paddingX={1}
                                alignItems={'center'}
                                justifyContent={'space-between'}
                              >
                                <Box sx={{ display: 'flex', alignItems: 'center' }} >
                                  <FormControlLabel label="PayPal" value={2} control={<Radio />} />
                                </Box>
                                <Iconify
                                  icon="logos:paypal"
                                  sx={{ width: 30, height: 30 }}
                                />

                              </Stack>
                            </Stack>

                          </RadioGroup>
                        </Box>


                      </>}


                    <FormControlLabel
                      control={<Checkbox defaultChecked />}
                      label="Envoyez-moi des e-mails sur les meilleurs événements ayant lieu dans mes environ ou en ligne."
                    />
                  </Grid>

                }
                <Grid item xs={12}>
                  {/** button commande */}
                  <Card
                    elevation={5}
                    sx={{
                      position: "fixed",
                      bottom: 0,
                      left: 0,
                      right: 0,
                      padding: 2,
                      borderTopRightRadius: '10px',
                      borderTopLeftRadius: '10px',
                      zIndex: 999
                    }}
                  >
                    <Stack spacing={1}>
                      <Stack direction="row" justifyContent="end">
                        <Stack direction="row" spacing={1}>
                          <Stack
                            direction="row"
                            spacing={0.1}
                            onClick={() => {
                              handleOpenRecap();
                            }}
                          >

                            <Iconify
                              icon="material-symbols-light:info-outline"
                              sx={{ width: 24, height: 24 }}
                            />
                          </Stack>
                          <Sheet isOpen={openRecap} onClose={() => setOpenRecap(false)} snapPoints={[450]}>
                            <Sheet.Container>
                              <Sheet.Header />
                              <Sheet.Content>
                                <Box sx={{ overflowX: "auto", overflowY: "auto" }}>
                                  <Grid container>
                                    <Grid item xs={12}>
                                      <Box display="flex" justifyContent="end">
                                      </Box>
                                      <Stack spacing={2} padding={2}>
                                        <Typography variant="h6" fontWeight={500}>
                                          Résumé de la commande
                                        </Typography>
                                        {ticketsList.map((billet, index) => (
                                          <Box color="#999999">
                                            <Stack
                                              direction="row"
                                              justifyContent="space-between"
                                            >
                                              <Typography>
                                                {billet.quantite}x {billet.nom}
                                              </Typography>
                                              <Typography>{shortNumber(billet.prix)} CFA</Typography>
                                            </Stack>
                                          </Box>
                                        ))}
                                        <Divider />
                                        {
                                          (moyenPaie === 1 || moyenPaie === 2 || reduction) &&
                                          <>
                                            <Stack direction="row" justifyContent="space-between">
                                              <Typography color="#999999">Sous-total</Typography>
                                              <Typography color="#999999">{shortNumber(montant)} CFA</Typography>
                                            </Stack>
                                            {(moyenPaie === 1 || moyenPaie === 2) && (
                                              <>

                                                <Stack direction="row" justifyContent="space-between">
                                                  <Typography color="#999999">Frais</Typography>
                                                  <Typography color="#999999">{reduction ? shortNumber((montant - reduction.value) * fee / 100) : shortNumber(montant * fee / 100)} CFA</Typography>
                                                </Stack>
                                              </>

                                            )}

                                            {reduction && (
                                              <Stack color="#999999" direction="row" justifyContent="space-between">
                                                <Typography>Réduction</Typography>
                                                <Typography>
                                                  {shortNumber(reduction.value)} CFA
                                                </Typography>
                                              </Stack>
                                            )}
                                            <Divider />
                                          </>
                                        }


                                        <Stack direction="row" justifyContent="space-between">
                                          <Typography fontSize={17} fontWeight={500}>Total à payer</Typography>
                                          <Typography fontSize={17} fontWeight={500}>
                                            {reduction ? shortNumber(montant - reduction.value + ((montant - reduction.value) * fee / 100)) : shortNumber(montant + (montant * fee / 100))} CFA
                                          </Typography>
                                        </Stack>
                                      </Stack>
                                    </Grid>
                                  </Grid>
                                </Box>
                              </Sheet.Content>
                            </Sheet.Container>
                            <Sheet.Backdrop />
                          </Sheet>
                          <Typography
                            fontSize={17}
                            fontWeight={500}
                            align="center"
                            paddingBottom={1}
                          >
                            {reduction ? shortNumber(montant - reduction.value + ((montant - reduction.value) * fee / 100)) : shortNumber(montant + (montant * fee / 100))} CFA
                          </Typography>
                        </Stack>
                      </Stack>

                      <Typography textAlign={'center'} variant="caption">En cliquant sur Confirmer la commande, j'accepte les conditions générales de Choptonticket</Typography>

                      {
                        procedCheckCP ? <CustomButton fullWidth disabled={loading} type={'primaire'} buttonText={'Comfirmer la commande'} onClick={pay} /> : loading ? <CircularProgress /> : <CustomButton fullWidth disabled={loading} type={'primaire'} buttonText={'Comfirmer la commande'} onClick={pay} />
                      }


                    </Stack>

                  </Card>
                </Grid>
              </Grid>
            </Grid>
            {/** modal recap */}

            {/** resumé de la commande
             * <Grid item xs={12} sm={4} sx={{ bgcolor: "#F2ECFD" }}>
              
            </Grid>
             * 
             */}
          </Grid>
        </Box>
      )}
    </Box>
  );
}
