import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { UserAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { collection, doc, getDoc, getDocs, orderBy, query } from "firebase/firestore";
import { db, functions } from "../../firebase.config";
import { httpsCallable } from "firebase/functions";
import { Card, Container, Grid, IconButton, Skeleton, Stack } from "@mui/material";
import Iconify from "../Iconify";
import Ticket from "../profil/ticket";


const options = ["Deconnexion"];

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

export default function FuturTickets() {
    const today = new Date();
    const today1 = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate()
    );
    const { user } = UserAuth();
    const [loading, setLoading] = React.useState(false);
    const [infos, setInfo] = React.useState([]);
    const [order, setOrder] = React.useState([]);

    const handleTimestamp = (e) => {
        const date = e.toDate();
        const tmp = date.toJSON().split(".");
        return tmp[0];
    };
    React.useEffect(() => {
        (async () => {
            try {
                setLoading(true)

                if (user) {
                    setOrder([])
                    console.log(user);
                    const u1 = await getDoc(doc(db, "users", `${user.uid}`));
                    if (u1.exists()) {
                        console.log(u1.data());
                        const addMessage = httpsCallable(functions, "retreiveTicket");
                        const result = await addMessage({ user: u1.data() });
                        console.log(result);
                        const q = query(collection(db, `users/${user.uid}/commandes`), orderBy('dateCreated', "desc"));
                        const querySnapshot = await getDocs(q);
                        // setEvents(querySnapshot.docs);
                        querySnapshot.forEach(async (document) => {
                            // sort list
                            console.log(document.data());
                            const element = document.data();
                            const refEvent = await getDoc(
                                doc(db, "evenement", `${element.event}`)
                            );
                            /* element.billets.forEach(async (billet) => {
                                const refBillet = await getDoc(
                                    doc(
                                        db,
                                        `evenement/${element.event}/billets`,
                                        `${billet.billetID}`
                                    )
                                );
                                if (refBillet.exists) {
                                    const tmp = {
                                        billet: refBillet.data(),
                                        event: refEvent.data(),
                                        commande: element,
                                    };
                                    // console.log("tmp : ", tmp);
                                    setInfo((infos) => [...infos, tmp]);
                                } else {
                                    const refBillebtOld = await getDoc(
                                        doc(
                                            db,
                                            `users/${user.uid}/oldBillets`,
                                            `${billet.billetID}`
                                        )
                                    );
                                    const tmp = {
                                        billet: refBillebtOld.data(),
                                        event: refEvent.data(),
                                        commande: element,
                                    };
                                    // console.log("tmp : ", tmp);
                                    setInfo((infos) => [...infos, tmp]);
                                }
                            }); */
                            if (
                                new Date(handleTimestamp(refEvent?.data().dateFin)) > today1 &&
                                refEvent?.data().status === 1
                            ) {
                                const tmp = {
                                    event: refEvent,
                                    commande: document,
                                };
                                // console.log("tmp : ", tmp);
                                setOrder((infos) => [...infos, tmp]);
                            }

                        });
                    }
                }
            } catch (error) {
                console.log(error);
            }
            setLoading(false)
        })();
    }, [user]);

    return (
        <>

            <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
                <Box sx={{ width: { xs: "100%" } }}>
                    {
                        loading ? <Card sx={{ marginY: 2 }}>
                            <Grid padding={3} container spacing={2}>
                                <Grid item xs={12} sm={4}>
                                    <Skeleton variant="rectangular" height={140} />
                                </Grid>
                                <Grid item xs={12} sm={8}>
                                    <Skeleton width="60%" />
                                    <Skeleton width="80%" />
                                    <Skeleton width="100%" />
                                    <Skeleton width="40%" />
                                </Grid>
                            </Grid>
                        </Card>
                            :
                            <>
                                {order.map((info) => (
                                    <Ticket info={info} />
                                ))}
                            </>
                    }
                </Box>
            </Box>


        </>
    );
}
