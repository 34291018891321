import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Backdrop,
  Box,
  Button,
  Card,
  CardMedia,
  Checkbox,
  Container,
  Divider,
  Fade,
  FormControlLabel,
  Grid,
  IconButton,
  Modal,
  Radio,
  RadioGroup,
  Skeleton,
  Stack,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import Cookies from "js-cookie";
import React from "react";
import { db } from "../../firebase.config";
import CustomButton from "../CustomButton";
import EventCard from "../EventCard";
import Iconify from "../Iconify";
import { format } from "date-fns";
import { fr } from "date-fns/locale";
import ReserMobile2 from "./resMobile2";
import Timer from "./Timer";
import { useParams } from "react-router-dom";
import { UserAuth } from "../../context/AuthContext";
import { shortNumber } from "../util/formatNumber";
import { launchCleanExpiredCodePromoUsedTask } from "../api/api";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  height: "100%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
};
const styleCancelOrder = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  height: "100%",
  bgcolor: "background.paper",
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  p: 2,
};

const ReserMobile = ({ eventID, widget, config }) => {
  //const navigate = useNavigate();
  const { user } = UserAuth();
  const [tickets, setTicket] = React.useState([]);
  const [ticketsList, setTicketList] = React.useState([]);
  const [seances, setSeance] = React.useState([]);
  const [seanceActive, setSeanceActive] = React.useState(0);
  const [event, setEvent] = React.useState();
  const [refreh, setRefresh] = React.useState(false);
  const [ticketTotals, setTicketTotal] = React.useState(0);
  const [quantite, setQuantite] = React.useState([]);
  const [montant, setMontant] = React.useState(0);
  const [reserve, setReserve] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const [total, setTotal] = React.useState(montant);
  // Customization params

  const addTicket = (index) => {
    console.log("index : ", index);
    var tmp = montant;
    tmp = tmp + tickets[index].data().price;
    setMontant(tmp);
    setTicketTotal(parseInt(ticketTotals + 1));
    const tmp1 = { ...quantite };
    tmp1[index] += 1;
    setQuantite(tmp1);
    // new implementation
    if (ticketsList.length === 0) {
      setTicketList([
        {
          id: tickets[index].id,
          nom: tickets[index].data().ticket_name,
          prix: tickets[index].data().price,
          quantite: parseInt(1),
        },
      ]);
    } else {
      const exist = ticketsList.findIndex(({ id }) => id === tickets[index].id);
      if (exist === -1) {
        setTicketList((existItem) => {
          return [
            ...existItem.slice(0, ticketsList.length),
            {
              id: tickets[index].id,
              nom: tickets[index].data().ticket_name,
              prix: tickets[index].data().price,
              quantite: parseInt(1),
            },
            ...existItem.slice(ticketsList.length + 1),
          ];
        });
      } else {
        setTicketList((existItem) => {
          return [
            ...existItem.slice(0, exist),
            {
              id: tickets[index].id,
              nom: tickets[index].data().ticket_name,
              prix: tickets[index].data().price,
              quantite: parseInt(ticketsList[exist].quantite + 1),
            },
            ...existItem.slice(exist + 1),
          ];
        });
      }
    }

    console.log(ticketsList);
  };

  const removeTicket = (index) => {
    if (quantite[index] > 0) {
      var tmp = montant;
      tmp = tmp - tickets[index].data().price;
      setMontant(tmp);
      setTicketTotal(parseInt(ticketTotals - 1));
      const tmp1 = { ...quantite };
      tmp1[index] -= 1;
      setQuantite(tmp1);

      const exist = ticketsList.findIndex(({ id }) => id === tickets[index].id);
      console.log(exist);
      if (exist !== -1) {
        if (ticketsList[exist].quantite === 1) {
          setTicketList((existItem) => {
            return [
              ...existItem.slice(0, exist),
              ...existItem.slice(exist + 1),
            ];
          });
        } else {
          setTicketList((existItem) => {
            return [
              ...existItem.slice(0, exist),
              {
                id: tickets[index].id,
                nom: tickets[index].data().ticket_name,
                prix: tickets[index].data().price,
                quantite: parseInt(ticketsList[exist].quantite - 1),
              },
              ...existItem.slice(exist + 1),
            ];
          });
        }
      }
      console.log(ticketsList);
    }
  };
  function extractStringBetweenChars(str, startChar, endChar) {
    const startIndex = str.indexOf(startChar);
    let endIndex = 0 // Recherche à partir de l'indice suivant de startChar
    if (str.indexOf(endChar) < 0) {
      endIndex = str.length;
    }
    else {
      endIndex = str.indexOf(endChar, startIndex + 1);
    }
    if (startIndex !== -1 && endIndex !== -1) {
      return str.substring(startIndex + startChar.length, endIndex);
    } else {
      return ""; // Retourne une chaîne vide si les caractères spécifiés ne sont pas trouvés
    }
  }



  const [moyenPaie, setMoyenpaie] = React.useState(0);
  const handleRadioChange = (event) => {
    var tmp = event.target.value;
    if (parseInt(tmp) === 0) {
      setTotal(montant);
      console.log(total);
    } else if (parseInt(tmp) === 1) {
      setTotal(montant + 200);
    } else {
      setTotal(montant + 500);
    }
    setMoyenpaie(event.target.value);
  };

  // if seance est active
  const changeSeance = (index) => {
    setLoading(true)
    setTicket([]);
    setQuantite([]);
    setSeanceActive(index);
    try {
      seances[index].data().billets.forEach(async (billet) => {
        if (billet.status === 1) {
          const snapshot = await getDoc(
            doc(db, `evenement/${eventID}/tickets`, `${billet.id}`)
          );
          if (snapshot.exists()) {
            // console.log("Mes billet", snapshot.data());
            setTicket((tickets) => [...tickets, snapshot]);
            setQuantite((quantite) => [...quantite, 0]);
          }
        }
      });
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }

    
  };
  const handleTimestamp = (e) => {
    if (e) {
      const date = e.toDate();
      const tmp = date.toJSON().split(".");
      return tmp[0];
    } else {
      return "24/02/2013";
    }
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [cancelOrder, setCancelOrder] = React.useState(false);
  const [openCancelOrder, setOpenCancelOrder] = React.useState(false);
  const handleOpenCancelOrder = () => setOpenCancelOrder(true);
  const handleCloseCancelOrder = () => setOpenCancelOrder(false);

  React.useEffect(() => {
    (async () => {
      setTicket([]);
      setSeance([]);
      setQuantite([]);
      try {
        setLoading(true);
        // const q = query(collection(db, `evenement/${eventID}/tickets`));
        const docRef = doc(db, "evenement", `${eventID}`);
        const docSnap = await getDoc(docRef);
        setEvent(docSnap.data());
        // console.log(docSnap.data());
        const tmpdate = new Date(format(
          new Date(Date.now()),
          "yyyy-MM-dd",
        ))
        if (docSnap.data().seanceActive === 1) {
          const q1 = query(
            collection(db, `evenement/${eventID}/seances`),
            where('date', ">=", tmpdate),
            orderBy("date", "asc")
          );
          const snapshot1 = await getDocs(q1);
          snapshot1.forEach((d) => {
            setSeance((seances) => [...seances, d]);
          });
          snapshot1.docs[0].data().billets.forEach(async (billet) => {
            if (billet.status === 1) {
              const snapshot = await getDoc(
                doc(db, `evenement/${eventID}/tickets`, `${billet.id}`)
              );
              if (snapshot.exists()) {
                // console.log("Mes billet", snapshot.data().ticket_name);
                setTicket((tickets) => [...tickets, snapshot]);
                setQuantite((quantite) => [...quantite, 0]);
              }
            }
          });
        } else {
          const q = query(collection(db, `evenement/${eventID}/tickets`), where('status', '==', 1));
          const snapshot = await getDocs(q);
          snapshot.forEach((d) => {
            setTicket((tickets) => [...tickets, d]);
            setQuantite((quantite) => [...quantite, 0]);
          });
        }
        // setLoading(false);
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    })();
  }, [refreh, user, open]);

  React.useEffect(() => {
    (async () => {
      setMontant(0)
      setTicketList([]);
    })();
  }, [cancelOrder, seanceActive]);

  React.useEffect(() => {
    (async () => {
      try {
        const discount_using_code = Cookies.get(`discount_using_code`)
        console.log(discount_using_code)
        // lancer la requête de clean
        if (discount_using_code !== 'undefined') {
          await launchCleanExpiredCodePromoUsedTask(discount_using_code)
        }
      } catch (error) {

      }
    })();
  }, [open]);

  return (

    <Box>
      <Grid container>
        {/** info de la commande */}
        <Grid item xs={12}>
          <Grid container>
            <Grid
              sx={ticketsList.length !== 0 ? { overflowX: "auto", overflowY: "auto", height: 'calc(100vh - 150px)' } : { overflowX: "auto", overflowY: "auto", height: 'calc(100vh - 50px)' }}
              item
              xs={12}
              paddingY={2}

            >
              {
                event?.seanceActive === 1 && seances.length === 0 ?
                  <>
                    <Typography
                      variant="h6"
                      fontWeight={500}
                    >
                      Selectionner une date
                    </Typography>
                    <Typography>Aucune date disponible pour l'instant</Typography>
                  </>
                  : event?.seanceActive === 1 && seances.length > 0 ?
                    <>
                      <Typography

                        variant="h6"
                        fontWeight={600}
                      >
                        Choisissez vos billets
                      </Typography>
                      <Stack
                        spacing={2}
                        paddingY={2}
                        direction="row"
                        sx={{ overflowX: "auto", overflowY: "auto", width: "100%", }}
                      >
                        {seances.map((seance, index) =>
                          seanceActive === index ? (
                            <Box
                              padding={2}
                              sx={{
                                borderRadius: "7px",
                                border: "2px solid transparent",
                                cursor: "pointer",
                                borderColor: "#5E17EB",
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                "&:hover": {
                                  borderColor: "#5E17EB",
                                },
                              }}
                              onClick={() => changeSeance(index)}
                            >
                              <Stack>
                                <Typography
                                  textAlign="center"
                                  sx={{ paddingBottom: 1 }}
                                >
                                  {format(
                                    new Date(handleTimestamp(seance?.data().date)),
                                    "EEEE",
                                    { locale: fr }
                                  )}
                                </Typography>
                                <Typography
                                  textAlign="center"
                                >
                                  {format(
                                    new Date(handleTimestamp(seance?.data().date)),
                                    "MMM yyyy",
                                    { locale: fr }
                                  )}
                                </Typography>
                                <Box sx={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',

                                }}>
                                  <Avatar sx={{ bgcolor: "#5E17EB", }}>
                                    <Typography
                                      textAlign="center"
                                    >
                                      {format(
                                        new Date(handleTimestamp(seance?.data().date)),
                                        "dd",
                                        { locale: fr }
                                      )}
                                    </Typography></Avatar>
                                </Box>


                                <Typography
                                  textAlign="center"
                                >
                                  {format(
                                    new Date(handleTimestamp(seance?.data().date)),
                                    "hh:mm",
                                    { locale: fr }
                                  )}
                                </Typography>
                              </Stack>
                            </Box>
                          ) : (
                            <Box
                              padding={2}
                              sx={{
                                borderRadius: "7px",
                                border: "2px solid #f2f2f2",
                                cursor: "pointer",
                                "&:hover": {
                                  borderColor: "#5E17EB",
                                },
                              }}
                              onClick={() => changeSeance(index)}
                            >
                              <Stack>
                                <Typography
                                  textAlign="center"
                                  sx={{ paddingBottom: 1 }}
                                >
                                  {format(
                                    new Date(handleTimestamp(seance?.data().date)),
                                    "EEEE",
                                    { locale: fr }
                                  )}
                                </Typography>
                                <Typography
                                  textAlign="center"
                                >
                                  {format(
                                    new Date(handleTimestamp(seance?.data().date)),
                                    "MMM yyyy",
                                    { locale: fr }
                                  )}
                                </Typography>
                                <Box sx={{

                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',

                                }}>
                                  <Avatar sx={{ color: "#5E17EB", bgcolor: '#f2f2f2' }}>
                                    <Typography
                                      textAlign="center"
                                    >
                                      {format(
                                        new Date(handleTimestamp(seance?.data().date)),
                                        "dd",
                                        { locale: fr }
                                      )}
                                    </Typography></Avatar>
                                </Box>
                                <Typography
                                  textAlign="center"
                                >
                                  {format(
                                    new Date(handleTimestamp(seance?.data().date)),
                                    "hh:mm",
                                    { locale: fr }
                                  )}
                                </Typography>
                              </Stack>
                            </Box>
                          )
                        )}
                      </Stack>
                    </> : null
              }

              <Typography
                paddingBottom={2}
                variant="h6"
                fontWeight={500}
              >
                Choisissez vos billets
              </Typography>

              <Grid container spacing={2} paddingBottom={ticketsList.length !== 0 ? 7 : 3}>
                {tickets.length === 0 && loading ? (
                  <>
                    <Grid item xs={12} md={12}>
                      <Skeleton variant="rectangular" height={150} />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Skeleton variant="rectangular" height={150} />
                    </Grid>
                  </>
                ) : tickets.length === 0 ?
                  <Box sx={{ bgcolor: '#f2f2f2', borderRadius: '10px', padding: 2, marginTop: 2 }}>
                    <Typography
                      fontSize={17}
                      fontWeight={600}
                      color="#1F1F1F"
                      textAlign={'center'}
                    >
                      Aucun billet mise à disposition
                      pour l'instant, mais vous pourriez être
                      intéressé d'autres événements.
                    </Typography>
                  </Box>
                  : (
                    tickets?.map((ticket, index) => (
                      <Grid item xs={12} md={12}>
                        <Box
                          sx={{
                            padding: 4,
                            bgcolor: `${config?.color != null ? config?.color.secondaryColor : '#F3EDFD'}`,
                            color: "#1F1F1F",
                            borderRadius: "10px",
                          }}
                        >
                          <Grid container justifyContent="space-between">
                            <Grid>
                              <Typography fontSize={17} fontWeight={550}>
                                {ticket?.data()?.ticket_name}
                              </Typography>
                              <Typography fontSize={17}>
                                {ticket?.data().price === 0
                                  ? "Gratuit"
                                  : shortNumber(ticket?.data().price) + " CFA"}
                              </Typography>
                            </Grid>
                            {seances.length !== 0 ? (
                              <Grid>
                                {seances[seanceActive].data().billets[
                                  seances[seanceActive]
                                    .data()
                                    .billets.findIndex(
                                      ({ id }) => id === ticket.id
                                    )
                                ]?.quota ===
                                  seances[seanceActive].data().billets[
                                    seances[seanceActive]
                                      .data()
                                      .billets.findIndex(
                                        ({ id }) => id === ticket.id
                                      )
                                  ].sell &&
                                  seances[seanceActive].data().billets[
                                    seances[seanceActive]
                                      .data()
                                      .billets.findIndex(
                                        ({ id }) => id === ticket.id
                                      )
                                  ].sell !== 0 ? (
                                  <Box
                                    component="button"
                                    padding={0.5}
                                    sx={{
                                      borderRadius: "5px",
                                      fontSize: 11,
                                      backgroundColor: "#FBE79D",
                                      border: "1px dashed #FBE79D",
                                    }}
                                  >
                                    épuisé
                                  </Box>
                                ) : (
                                  <Stack direction="row" spacing={1}>
                                    {quantite[index] === 0 ? (
                                      <IconButton
                                        size="small"
                                        disabled
                                        aria-label="fingerprint"
                                        sx={{
                                          bgcolor: "#5E17EB",
                                          color: "white",
                                        }}
                                      >
                                        <Iconify icon="ic:baseline-minus" />
                                      </IconButton>
                                    ) : (
                                      <IconButton
                                        onClick={() =>
                                          removeTicket(index)
                                        }
                                        size="small"
                                        aria-label="fingerprint"
                                        sx={{
                                          bgcolor: "#5E17EB",
                                          color: "white",
                                        }}
                                      >
                                        <Iconify icon="ic:baseline-minus" />
                                      </IconButton>
                                    )}
                                    <Typography fontSize={17}>
                                      {quantite[index]}
                                    </Typography>
                                    <IconButton
                                      onClick={() => addTicket(index)}
                                      size="small"
                                      aria-label="fingerprint"
                                      sx={{
                                        bgcolor: "#5E17EB",
                                        color: "white",
                                      }}
                                    >
                                      <Iconify icon="material-symbols:add" />
                                    </IconButton>
                                  </Stack>
                                )}
                              </Grid>
                            ) : (
                              <Grid>
                                {ticket.data().quota >
                                  ticket.data().sell + (ticket.data().encours || 0) ? (
                                  <Stack direction="row" spacing={1}>
                                    {quantite[index] === 0 ? (
                                      <IconButton
                                        size="small"
                                        disabled
                                        aria-label="fingerprint"
                                        sx={{
                                          bgcolor: "#5E17EB",
                                          color: "white",
                                        }}
                                      >
                                        <Iconify icon="ic:baseline-minus" />
                                      </IconButton>
                                    ) : (
                                      <IconButton
                                        onClick={() =>
                                          removeTicket(index)
                                        }
                                        size="small"
                                        aria-label="fingerprint"
                                        sx={{
                                          bgcolor: "#5E17EB",
                                          color: "white",
                                        }}
                                      >
                                        <Iconify icon="ic:baseline-minus" />
                                      </IconButton>
                                    )}
                                    <Typography fontSize={17}>
                                      {quantite[index]}
                                    </Typography>
                                    <IconButton
                                      onClick={() => addTicket(index)}
                                      size="small"
                                      aria-label="fingerprint"
                                      sx={{
                                        bgcolor: "#5E17EB",
                                        color: "white",
                                      }}
                                    >
                                      <Iconify icon="material-symbols:add" />
                                    </IconButton>
                                  </Stack>
                                ) : (

                                  <Box
                                    component="button"
                                    padding={0.5}
                                    sx={{
                                      borderRadius: "5px",
                                      fontSize: 11,
                                      backgroundColor: "#FBE79D",
                                      border: "1px dashed #FBE79D",
                                    }}
                                  >
                                    épuisé
                                  </Box>
                                )}
                              </Grid>
                            )}
                          </Grid>
                          {ticket.data().description &&
                            ticket.data().description !== "" && (
                              <Grid>
                                <Box>
                                  <Accordion
                                    elevation={0}
                                    sx={{ backgroundColor: "#F3EDFD" }}
                                  >
                                    <AccordionSummary
                                      expandIcon={
                                        <Iconify icon="ep:arrow-down-bold" />
                                      }
                                      aria-controls="panel1a-content"
                                      id="panel1a-header"
                                    >
                                      <Typography
                                        fontSize={17}
                                        sx={{ color: "#1F1F1F" }}
                                      >
                                        Détail sur le billet
                                      </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails
                                      sx={{
                                        backgroundColor: "white",
                                        borderRadius: "7px",
                                      }}
                                    >
                                      <Typography variant="body2">
                                        {ticket.data().description}
                                      </Typography>
                                    </AccordionDetails>
                                  </Accordion>
                                </Box>
                              </Grid>
                            )}
                        </Box>
                      </Grid>
                    ))
                  )}
              </Grid>
            </Grid>
          </Grid>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={openCancelOrder}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
              backdrop: {
                timeout: 500,
              },
            }}
          >
            <Fade in={openCancelOrder}>
              <Box sx={styleCancelOrder}>
                <Stack spacing={3} justifyContent={'center'} alignItems={'center'}>
                  <Typography variant="h6"
                    fontWeight={500} id="transition-modal-description" sx={{ mt: 2 }}>
                    Voulez-vous annuler la commande ?
                  </Typography>
                  <Typography textAlign={'center'} id="transition-modal-description" sx={{ mt: 2 }}>
                    Voulez-vous vraiment quitter le règlement ? Les billets que vous
                    avez sélectionnés ne seront peut-être plus disponibles
                    ultérieurement.
                  </Typography>
                  <Box
                    sx={{ width: "100%", display: "flex", justifyContent: "center", columnGap: 2 }}
                  >
                    <CustomButton type={'secondaire'} buttonText={'rester'} onClick={() => {
                      setOpenCancelOrder(false);
                    }} />
                    <CustomButton type={'primaire'} buttonText={'Annuler'} onClick={() => {
                      handleClose()
                      setOpenCancelOrder(false);
                      setCancelOrder(!cancelOrder)
                    }} />


                  </Box>
                </Stack>
              </Box>
            </Fade>
          </Modal>
          {ticketsList.length !== 0 && (
            <Card
              elevation={5}
              sx={{
                position: "fixed",
                bottom: 0,
                left: 0,
                right: 0,
                padding: 3,
                borderTopRightRadius: '10px',
                borderTopLeftRadius: '10px'
              }}
            >

              <Stack direction="row" justifyContent="space-between">
                <Typography fontSize={17}
                  fontWeight={500} align="center" paddingBottom={1}>
                  Total
                </Typography>
                <Typography
                  fontSize={17}
                  fontWeight={500}
                  align="center"
                  paddingBottom={1}
                >
                  {shortNumber(montant)} CFA
                </Typography>
              </Stack>
              <CustomButton buttonText={'Réserver maintenant'} type={'primaire'} onClick={handleOpen} fullWidth />

              {/** Verification de la commande et payement */}
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Stack padding={1} direction="row">
                    <IconButton onClick={handleOpenCancelOrder}>

                      <Iconify
                        inline={true}
                        icon="material-symbols:arrow-back-rounded"
                        sx={{ width: 24, height: 24, color: "#1F1F1F" }}
                      />
                    </IconButton>
                    <Stack
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                      }}
                    >

                      <Typography
                        variant="h6"
                        fontWeight={550}
                        textAlign="center"
                      >
                        Commander
                      </Typography>
                      <Timer />
                    </Stack>
                    <IconButton >
                      <Iconify
                        icon="solar:close-circle"
                        sx={{ width: 24, height: 24 }}
                      />
                    </IconButton>
                  </Stack>

                  <Divider />
                  <ReserMobile2
                    event={event}
                    ticketsList={ticketsList}
                    montant={montant}
                    seance={seances.length !== 0 ? seances[seanceActive].id : null}
                  />
                </Box>
              </Modal>
            </Card>
          )}
        </Grid>
      </Grid>
    </Box>

  );
};

export default ReserMobile;
