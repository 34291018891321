import React, { createContext, useEffect, useState } from "react";
import Cookies from "js-cookie";

export const LocationContext = createContext();

const LocationProvider = ({ children }) => {
  const [location, setLocation] = useState(null);

  useEffect(() => {
    const fetchAndStoreLocation = async () => {
      const fetchedLocation = await fetchUserLocation();
      if (fetchedLocation) {
        console.log('15665465-----------------------')
        setLocation(fetchedLocation);
        saveLocationToCookies(fetchedLocation);
      }

    };
    const location = getLocationFromCookies()
    console.log(location)
    if (location == null) {
      fetchAndStoreLocation();
    }
    // fetchAndStoreLocation();
  }, []);

  const fetchUserLocation = async () => {
    if (window.location.href.includes("fr/checkout")) {
      console.log("URL contains 'confirmation'. Skipping location fetch.");
      return null;
    }
    try {
      const response = await fetch("http://ip-api.com/json/");
      const data = await response.json();
      if (data.status === "success") {
        console.log(data)
        const dd = await getNeighborhood(data.lat, data.lon)
        return {
          commune: dd.commune,
          city: data.city,
          region: data.regionName,
          country: data.country,
          lat: data.lat,
          lon: data.lon,
        };
      } else {
        console.error("Error fetching location:", data.message);
        return null;
      }
    } catch (error) {
      console.error("Error fetching location:", error);
      return null;
    }
  };


  const saveLocationToCookies = (location) => {
    if (location) {
      Cookies.set("userLocation", JSON.stringify(location), { expires: 0.25 });
    }
  };

  const getLocationFromCookies = () => {
    const location = Cookies.get("userLocation");
    return location ? JSON.parse(location) : null;
  };

  const getNeighborhood = async (lat, lon) => {

    try {
      console.log("Quartier :");
      const response = await fetch(`https://nominatim.openstreetmap.org/reverse?lat=${lat}&lon=${lon}&format=json`);
      const data = await response.json();

      console.log("Quartier :", data);

      return {
        commune: data.address.suburb || data.address.village || data.address.town || null,
      };
    } catch (error) {
      console.error("Erreur de géocodage inversé :", error);
      return null;
    }
  };


  return (
    <LocationContext.Provider value={location}>
      {children}
    </LocationContext.Provider>
  );
};

export default LocationProvider;
