import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Button,
  Card,
  CardContent,
  CardMedia,
  Checkbox,
  CircularProgress,
  Container,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Modal,
  Radio,
  RadioGroup,
  Skeleton,
  Stack,
  TextField,
} from "@mui/material";
import CustomButton from "../Components/CustomButton";
import logoImg from "../media/suspect.jpeg";
import Iconify from "../Components/Iconify";
import {
  addDoc,
  arrayRemove,
  arrayUnion,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  increment,
  orderBy,
  query,
  setDoc,
  Timestamp,
  updateDoc,
  where,
  writeBatch,
} from "firebase/firestore";
import { db } from "../firebase.config";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { borderRadius } from "@mui/system";
import { format, isSameDay } from "date-fns";
import { fr, id } from "date-fns/locale";
import Maps from "../Components/map/Maps";
import useResponsive from "../Components/hooks/useResponsive";
import ReserDesktop from "../Components/reservation/reserDesktop";
import ReserMobile from "../Components/reservation/resMobile";
import Footer from "../Components/Footer";
import Contact from "../Components/Contact";
import { Variables } from "../context/VariableContext";
import { UserAuth } from "../context/AuthContext";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import Cookies from "js-cookie";
import AffTracker from "../Components/tracker/affTracker";
import FollowButton from "../Components/followButton";
import { useSnackbar } from "../context/snackbarContext";
import { launchCleanExpiredCodePromoUsedTask } from "../Components/api/api";
// import * as pay from "https://paydunya.com/assets/psr/js/psr.paydunya.min.js"

var paydunya = require("paydunya");

var setup = new paydunya.Setup({
  masterKey: "DNrUjYFV-6HiQ-NNu5-DAgU-WizSswjzZW6I",
  privateKey: "test_private_Qyqe0MaokWBMmU1m5d1kNHcj7fs",
  publicKey: "test_public_8FAyuNRDgwV2v8Yujc8QHvlYtuS",
  token: "vT8PF5dQR3zx7r9OcplJ",
  mode: "test",
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  height: "100%",
  bgcolor: "background.paper",
  border: "2px solid white",
  boxShadow: 24,
  p: 2,
};

export default function EventPage() {
  // quill rich text
  const modules = {
    toolbar: [
      // [{ 'font': [] }],
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      [{ align: [] }, { color: [] }, { background: [] }], // dropdown with defaults from theme
      ["clean"],
    ],
  };

  const formats = [
    // 'font',
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "align",
    "color",
    "background",
  ];
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const order = queryParams.get("order");
  const { eventID } = useParams();
  const { trackingID } = useParams();
  const { plateforme } = useParams();
  const { trackingSelling } = useParams();
  const [sellingID, setSellingID] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [tickets, setTicket] = React.useState([]);
  const [ticketsList, setTicketList] = React.useState([]);
  const [seances, setSeance] = React.useState([]);
  const [seanceActive, setSeanceActive] = React.useState(0);
  const [event, setEvent] = React.useState();
  const [refreh, setRefresh] = React.useState(false);
  const [ticketTotals, setTicketTotal] = React.useState(0);
  const [quantite, setQuantite] = React.useState([]);
  const [montant, setMontant] = React.useState(0);
  const [reserve, setReserve] = React.useState(false);
  const [org, setOrg] = React.useState([]);
  const [infoUser, setInfoUser] = React.useState([]);
  const [isFollow, setIsFollow] = React.useState(false);
  const [addDiscount, setAddDiscount] = React.useState(false);
  const [discountField, setDiscountField] = React.useState("");
  const [discount, setDiscount] = React.useState();
  const [discountMsg, setDiscountMsg] = React.useState("");
  const [startVerifyDiscount, setStartVerifyDiscount] = React.useState(false);
  const [discountLoad, setDiscountLoad] = React.useState(false);
  const [desc, setDesc] = useState("");
  const { user } = UserAuth();
  const [isSticky, setSticky] = useState(0);
  const ref = useRef(null);
  const showSnackbar = useSnackbar();
  const handleScroll = () => {
    if (ref.current) {
      setSticky(ref.current.getBoundingClientRect().top <= 0);
    }
  };

  const today = new Date();
  const today1 = new Date(today.getFullYear(), today.getMonth(), today.getDate(), today.getHours(), today.getMinutes());

  useEffect(() => {
    console.log("ok");
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", () => handleScroll);
    };
  }, []);

  // reserve field

  const [moyenPaie, setMoyenpaie] = React.useState(0);
  const handleRadioChange = (event) => {
    console.log(event.target.value);
    setMoyenpaie(event.target.value);
  };

  React.useEffect(() => {
    const fetchData = async () => {
      console.log("Fetching event data...");
      try {
        setLoading(true);
        setQuantite([]);
        setTicket([]);
        setSeance([]);

        // Récupérer les détails de l'événement
        const eventRef = doc(db, "evenement", `${eventID}`);
        const eventSnap = await getDoc(eventRef);

        if (!eventSnap.exists()) {
          console.warn("Event does not exist.");
          return;
        }

        const eventData = eventSnap.data();
        setEvent(eventData);

        // Récupérer l'organisation
        const orgSnap = await getDoc(doc(db, "organisations", eventData.orgId));
        setOrg(orgSnap.data());

        // Tracking
        if (trackingID && plateforme) {
          await handleTracking(eventData.orgId, eventID, trackingID, plateforme);
        }

        if (trackingSelling) {
          await handleSellingTracking(eventData.orgId, eventID, trackingSelling);
        }

        // Récupérer les billets ou les séances en fonction de la session active
        if (eventData.seanceActive === 1) {

          await fetchSeancesAndTickets(eventID);
        } else {

          await fetchTickets(eventID);
        }

        // Charger les informations utilisateur
        if (user) {
          const userSnap = await getDoc(doc(db, "users", user.uid));
          setInfoUser(userSnap.data());
        }

        // Charger la description de l'événement
        await fetchDescription(eventID);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [refreh, user]);

  // Utilitaire pour le tracking
  const handleTracking = async (orgId, eventID, trackingID, plateforme) => {
    console.log("Handling tracking...");
    const trackingRef = doc(
      db,
      `organisations/${orgId}/sosm/${orgId}/${eventID}`,
      "facebook"
    );
    const trackingSnap = await getDoc(trackingRef);

    if (trackingSnap.exists() && trackingSnap.data().code === trackingID) {
      await updateDoc(trackingRef, {
        click: increment(1),
      });
      navigate(`/fr/event/${eventID}/fb-${trackingID}`);
    } else {
      console.warn("Tracking ID does not match.");
    }
  };

  // Utilitaire pour le tracking des ventes
  const handleSellingTracking = async (orgId, eventID, trackingSelling) => {
    console.log("Handling selling tracking...");
    const [_, sellingCode] = trackingSelling.split("-");
    const sellingRef = doc(
      db,
      `organisations/${orgId}/sosm/${orgId}/${eventID}`,
      "facebook"
    );
    const sellingSnap = await getDoc(sellingRef);

    if (sellingSnap.exists() && sellingSnap.data().code === sellingCode) {
      setSellingID(trackingSelling);
    } else {
      console.warn("Selling ID does not match.");
    }
  };

  // Récupérer les séances et les billets
  const fetchSeancesAndTickets = async (eventID) => {
    console.log("Fetching seances and tickets...");
    const currentDate = new Date(format(new Date(Date.now()), "yyyy-MM-dd"));
    const seanceQuery = query(
      collection(db, `evenement/${eventID}/seances`),
      where("date", ">=", currentDate),
      orderBy("date", "asc")
    );

    const seanceSnap = await getDocs(seanceQuery);
    const tmpseances = seanceSnap.docs.map((doc) => doc);

    console.log(tmpseances.length);
    if (tmpseances.length > 0) {
      const newTickets = [];
      const newQuantite = [];
      for (const billet of tmpseances[0].data().billets) {
        console.log("loop...........................................................");
        if (billet.status === 1) {
          const ticketSnap = await getDoc(
            doc(db, `evenement/${eventID}/tickets`, billet.id)
          );
          if (ticketSnap.exists()) {
            const ticketExists = newTickets.some(
              (t) => t.id === ticketSnap.id
            );

            if (!ticketExists) {
              newTickets.push(ticketSnap);
              newQuantite.push(0);
            }
          }
        }
      }
      setTicket(newTickets);
      setQuantite(newQuantite);
    }
    setSeance(tmpseances);
  };


  // Récupérer les billets
  const fetchTickets = async (eventID) => {
    console.log("Fetching tickets...");
    const ticketQuery = query(
      collection(db, `evenement/${eventID}/tickets`),
      where("status", "==", 1)
    );

    const ticketSnap = await getDocs(ticketQuery);
    const tickets = ticketSnap.docs.map((doc) => doc);
    setTicket(tickets);
    setQuantite(new Array(tickets.length).fill(0));
  };

  // Récupérer la description
  const fetchDescription = async (eventID) => {
    console.log("Fetching description...");
    const descQuery = query(collection(db, `evenement/${eventID}/description`));
    const descSnap = await getDocs(descQuery);

    descSnap.forEach((doc) => {
      const data = doc.data();
      setDesc(data.valeur || data.value);
    });
  };

  React.useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const u1 = await getDoc(
          doc(db, `users/${user.uid}/followings`, event.orgId)
        );
        if (u1.exists()) {
          setIsFollow(true);
        } else {
          setIsFollow(false);
        }
      } catch (error) {
        console.log(error.msg);
      }
      setLoading(false);
    })();
  }, [refreh, event]);


  React.useEffect(() => {
    (async () => {
      setOpenContact(false);
      setOpenReservation(false);
    })();
  }, []);

  React.useEffect(() => {
    (async () => {
      try {
        const discount_using_code = Cookies.get(`discount_using_code`)
        console.log(discount_using_code)
        // lancer la requête de clean
        if (discount_using_code !== 'undefined') {
          await launchCleanExpiredCodePromoUsedTask(discount_using_code)
        }
      } catch (error) {

      }
    })();
  }, []);


  React.useEffect(() => {
    (async () => {
      if (order === 'unavalable') {
        showSnackbar("Une erreur s'est produite lors validation de votre commande", "error");
      }

    })();
  }, []);

  const verifyAvailabilty = (index, exist) => {
    const requestedTicket = parseInt(ticketsList[exist].quantite + 1)
    let result = 1
    if (requestedTicket > (tickets[index].data().quota - (tickets[index].data().sell + (tickets[index].data().encours || 0)))) {
      result = 0
    }
    return result
  }

  const addTicket = (index) => {
    console.log("index : ", index);

    // new implementation
    if (ticketsList.length === 0) {
      setTicketList([
        {
          id: tickets[index].id,
          nom: tickets[index].data().ticket_name,
          prix: tickets[index].data().price,
          quantite: parseInt(1),
        },
      ]);
    } else {
      const exist = ticketsList.findIndex(({ id }) => id === tickets[index].id);
      if (exist === -1) {
        setTicketList((existItem) => {
          return [
            ...existItem.slice(0, ticketsList.length),
            {
              id: tickets[index].id,
              nom: tickets[index].data().ticket_name,
              prix: tickets[index].data().price,
              quantite: parseInt(1),
            },
            ...existItem.slice(ticketsList.length + 1),
          ];
        });
      } else {
        const canAdd = verifyAvailabilty(index, exist)
        if (canAdd === 0) {
          console.log('quota atteint')
          showSnackbar("Vous avez atteind le nombre disponible pour ce type de billet", "success");
          return
        } else {
          setTicketList((existItem) => {
            return [
              ...existItem.slice(0, exist),
              {
                id: tickets[index].id,
                nom: tickets[index].data().ticket_name,
                prix: tickets[index].data().price,
                quantite: parseInt(ticketsList[exist].quantite + 1),
              },
              ...existItem.slice(exist + 1),
            ];
          });
        }

      }
    }
    var tmp = montant;
    tmp = tmp + tickets[index].data().price;
    setMontant(tmp);
    setTicketTotal(parseInt(ticketTotals + 1));
    const tmp1 = { ...quantite };
    tmp1[index] += 1;
    setQuantite(tmp1);
    console.log(ticketsList);
  };

  const removeTicket = (index) => {
    if (quantite[index] > 0) {
      var tmp = montant;
      tmp = tmp - tickets[index].data().price;
      setMontant(tmp);
      setTicketTotal(parseInt(ticketTotals - 1));
      const tmp1 = { ...quantite };
      tmp1[index] -= 1;
      setQuantite(tmp1);

      const exist = ticketsList.findIndex(({ id }) => id === tickets[index].id);
      console.log(exist);
      if (exist !== -1) {
        if (ticketsList[exist].quantite === 1) {
          setTicketList((existItem) => {
            return [
              ...existItem.slice(0, exist),
              ...existItem.slice(exist + 1),
            ];
          });
        } else {
          setTicketList((existItem) => {
            return [
              ...existItem.slice(0, exist),
              {
                id: tickets[index].id,
                nom: tickets[index].data().ticket_name,
                prix: tickets[index].data().price,
                quantite: parseInt(ticketsList[exist].quantite - 1),
              },
              ...existItem.slice(exist + 1),
            ];
          });
        }
      }
      console.log(ticketsList);
    }
  };


  // if seance est active
  const changeSeance = (index) => {
    setTicketTotal(0);
    setMontant(0)
    setTicketList([]);
    setOpenContact(false);
    setTicket([]);
    setQuantite([]);
    setSeanceActive(index);
    seances[index].data().billets.forEach(async (billet) => {
      if (billet.status === 1) {
        const snapshot = await getDoc(
          doc(db, `evenement/${eventID}/tickets`, `${billet.id}`)
        );
        if (snapshot.exists()) {
          // console.log("Mes billet", snapshot.data());
          setTicket((tickets) => [...tickets, snapshot]);
          setQuantite((quantite) => [...quantite, 0]);
        }
      }
    });
  };
  const handleTimestamp = (e) => {
    if (e) {
      const date = e.toDate();
      console.log(date)
      const tmp = date.toJSON().split(".");
      console.log(tmp)
      return tmp[0];
    } else {
      return "24/02/2013";
    }
  };

  const handleTimestampC1 = (e) => {
    const date = e.toDate();
    return format((new Date(date.toJSON())), 'yyyy-MM-dd', { locale: fr }).toString();
  };

  const handleTimestampC2 = (e) => {
    
    if (e) {
      const date = e.toDate();
    console.log(date)
    return format((new Date(date.toJSON())), 'HH:mm', { locale: fr }).toString();
    } else {
      return "24/02/2013";
    }
    
  };

  const smUp = useResponsive("up", "sm");

  const mdUp = useResponsive("up", "md");
  // Modal
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // modal contact

  const handleOpenContact = () => {
    setOpenContact(true);
  };
  const handleCloseContact = () => {
    setOpenContact(false);
  };

  const {
    openContact,
    setOpenContact,
    openReservation,
    setOpenReservation,
    openLogin,
    setOpenLogin,
  } = Variables();

  // follow

  const follow = async () => {
    if (!user) {
      setOpenLogin(true);
      return;
    }

    setLoading(true);

    try {
      const userFollowDocRef = doc(db, `users/${user.uid}/followings`, event.orgId);
      const orgContactDocRef = doc(db, `organisations/${event.orgId}/crm/${event.orgId}/contacts`, user.email);

      // Lire les documents en parallèle
      const [userFollowDoc, contactDoc] = await Promise.all([
        getDoc(userFollowDocRef),
        getDoc(orgContactDocRef)
      ]);

      const isFollowing = userFollowDoc.exists();
      const contactExists = contactDoc.exists();

      const userData = infoUser;
      const contactData = {
        uid: user.uid,
        nom: userData.nom,
        prenom: userData.prenom,
        updateDate: Timestamp.now(),
      };

      const batch = writeBatch(db);

      if (!isFollowing) {
        // Abonnement
        if (contactExists) {
          batch.update(orgContactDocRef, {
            ...contactData,
            abonne: true,
            push: true,
            newslettres: true,
          });
        } else {
          batch.set(orgContactDocRef, {
            ...contactData,
            abonne: true,
            push: true,
            newslettres: true,
            createDate: Timestamp.now(),
          });
        }

        batch.set(userFollowDocRef, {
          abonne: true,
          push: true,
          newslettres: true,
          updateDate: Timestamp.now(),
        });
      } else {
        // Désabonnement
        batch.update(orgContactDocRef, {
          abonne: false,
          push: false,
          newslettres: false,
          updateDate: Timestamp.now(),
        });

        batch.delete(userFollowDocRef);
      }

      // Exécuter toutes les opérations en une seule transaction
      await batch.commit();
    } catch (error) {
      console.error("Error during follow operation:", error);
      showSnackbar("Une erreur est survenue lors de l'abonnement.", "error");
    } finally {
      setLoading(false);
      setRefresh(prev => !prev);
    }
  };

  const [showDesc, setShowDesc] = React.useState(true);

  const formatSeanceDate = (dateDebutTimestamp, dateFinTimestamp) => {
    const dateDebut = new Date(handleTimestamp(dateDebutTimestamp));
    const dateFin = new Date(handleTimestamp(dateFinTimestamp));
  
    if (isSameDay(dateDebut, dateFin)) {
      // même jour : lun. 02 fév. 2025 20h45 - 23h45
      return `${format(dateDebut, 'EEE dd MMM yyyy HH', { locale: fr })}h${format(dateDebut, 'mm', { locale: fr })} - ${format(dateFin, 'HH', { locale: fr })}h${format(dateFin, 'mm', { locale: fr })}`;
    } else {
      // jours différents : lun. 02 fév. 2025 23h45 - mar. 03 fév. 2025 04h45
      return `${format(dateDebut, 'EEE dd MMM yyyy HH', { locale: fr })}h${format(dateDebut, 'mm', { locale: fr })} - ${format(dateFin, 'EEE dd MMM yyyy HH', { locale: fr })}h${format(dateFin, 'mm', { locale: fr })}`;
    }
  };

  return (
    <>
      {event ? (
        <>
          <Container sx={{ paddingTop: { xs: 6, sm: 10 }, paddingBottom: 20 }}>
            <AffTracker />
            <Box
              sx={{
                position: "absolute",
                width: "100%",
                left: 0,
                zIndex: -1,
                filter: " blur(5rem)",
              }}
            >
              {event && (
                <CardMedia
                  component="img"
                  sx={{ width: "100%", objectFit: "cover" }}
                  height="300"
                  image={event.idVisuel}
                  title="green iguana"
                />
              )}
            </Box>
            <>
              <Grid
                marginY={1}
                direction={{ xs: "column-reverse", lg: "row" }}
                container
                spacing={5}
                display="flex"
                alignItems="start"
              >
                <Grid spacing={1} item xs={12} sm={5} sx={{ width: "100%" }}>

                  <Box
                    sx={{
                      padding: 2,
                      borderRadius: "7px",
                      width: "100%",
                      bgcolor: "rgba(245, 245, 245, 0.60)",
                      marginBottom: 2,
                    }}
                  >
                    <Box paddingBottom={2}>
                      <Typography
                        variant="h4"
                        sx={{
                          color: "#141414",
                        }}
                      >
                        <strong>{event.name ? event.name : event.nomEvent}</strong>
                      </Typography>
                      <Stack direction={'row'} spacing={2}>
                        <Box
                          sx={{
                            border: " 1px solid #999999",
                            borderRadius: "20px",
                            bgcolor: "#1F1F1F",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Stack direction="row">
                            <Typography
                              variant="caption"
                              paddingX={2}
                              paddingY={0.5}
                              color="white"
                            >
                              {event.type}
                            </Typography>
                          </Stack>
                        </Box>
                      </Stack>
                      {/* event.seanceActive === 1 && (
                    <Typography fontSize={20} color="#1F1F1F">
                      Multidate
                    </Typography>
                  ) */}
                    </Box>
                    <Grid paddingY={1} container>
                      <Grid item xs={1}>
                        <Iconify
                          inline={true}
                          icon="material-symbols:date-range-outline"
                          sx={{ width: 24, height: 24, color: "#1F1F1F" }}
                        />
                      </Grid>
                      <Grid item xs={11} sm={8}>
                        <Stack>
                        <Typography paddingBottom={1} fontSize={17}>
                          
                            {event && formatSeanceDate(event.dateDebut, event.dateFin)}
                        </Typography>
                        
                        </Stack>
                        {" "}
                        
                        <Divider />
                      </Grid>
                    </Grid>

                    {
                      event?.locationType === 1 ?
                        <Grid paddingY={1} container>
                          <Grid item xs={1}>
                            <Iconify
                              inline={true}
                              icon="streamline:web"
                              sx={{ width: 24, height: 24, color: "#1F1F1F" }}
                            />
                          </Grid>
                          <Grid item xs={11} sm={8}>
                            {" "}
                            <Typography paddingBottom={1} fontSize={17}>
                              Evenement en ligne
                            </Typography>

                          </Grid>
                        </Grid>
                        :
                        <>
                          <Grid paddingY={1} container>
                            <Grid item xs={1}>
                              <Iconify
                                inline={true}
                                icon="material-symbols:home-outline"
                                sx={{ width: 24, height: 24, color: "#1F1F1F" }}
                              />
                            </Grid>
                            <Grid item xs={11} sm={8}>
                              {" "}
                              <Typography paddingBottom={1} fontSize={17}>
                                {event.lieu_event}
                              </Typography>
                              <Divider />
                            </Grid>
                          </Grid>

                          <Grid
                            paddingTop={1}
                            paddingBottom={2}
                            container
                            spacing={3}
                          >
                            <Grid item xs={1}>
                              <Iconify
                                inline={true}
                                icon="material-symbols:location-on-outline"
                                sx={{ width: 24, height: 24, color: "#1F1F1F" }}
                              />
                            </Grid>
                            <Grid item xs={10} sm={8}>
                              <Typography fontSize={17}>
                                {event.localisationDes ? event.localisationDes : `${event.lieu_event}, ${event.city}, ${event.country}`}
                              </Typography>
                            </Grid>
                          </Grid>
                        </>
                    }


                  </Box>

                  <Stack
                    direction={{ xs: "column", sm: "row" }}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    bgcolor="#F5F5F5"
                    padding={2}
                    sx={{
                      borderRadius: "7px",
                      width: "100%",
                      bgcolor: "rgba(245, 245, 245, 0.5)",
                    }}
                  >
                    <Stack
                      direction="row"
                      spacing={1}
                      display="flex"
                      justifyContent="start"
                      alignItems="center"
                    >
                      <Typography paddingY={2} fontSize={17} color="#999999">
                        Organisé par{" "}
                      </Typography>
                      <Typography fontSize={17}>
                        <strong>{org.nomOrg}</strong>
                      </Typography>
                    </Stack>
                    <FollowButton follow={follow} event={event} />
                    
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={7} sx={{ width: "100%" }}>
                  <Card
                    elevation={!smUp ? 0 : 10}
                    sx={{
                      borderRadius: { xs: "7px", sm: "7px" },
                      bgcolr: "white",
                    }}
                  >
                    {event &&
                      (!smUp ? (
                        <CardMedia
                          component="img"
                          sx={{ width: "100%", objectFit: "cover" }}
                          height="200"
                          image={event.idVisuel}
                          title="green iguana"
                        />
                      ) : (
                        <CardMedia
                          component="img"
                          sx={{ width: "100%", objectFit: "cover" }}
                          height="425"
                          image={event.idVisuel}
                          title="green iguana"
                        />
                      ))}
                  </Card>
                </Grid>
              </Grid>
              <Grid spacing={5} container paddingY={5}>
                {seances.length !== 0 && mdUp && (
                  <Grid item xs={12} md={7}>
                    <Typography paddingBottom={2} variant="h5" fontWeight={600}>
                      Séléctionnez un date { }
                    </Typography>
                    <Stack direction="row" spacing={2} sx={{ overflowX: "auto", overflowY: "auto"}}>
                      {seances?.map((seance, index) =>
                        seanceActive === index ? (
                          <Box
                            padding={2}
                            sx={{
                              borderRadius: "7px",
                              border: "2px solid transparent",
                              cursor: "pointer",
                              borderColor: "#5E17EB",
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              "&:hover": {
                                borderColor: "#5E17EB",
                              },
                            }}
                            onClick={() => changeSeance(index)}
                          >
                            <Stack>
                              <Typography
                                textAlign="center"
                                sx={{ paddingBottom: 1 }}
                              >
                                {format(
                                  (new Date(seance?.data().date.toDate().toJSON())),
                                  "EEEE",
                                  { locale: fr }
                                )}
                              </Typography>
                              <Typography
                                textAlign="center"
                              >
                                {format(
                                  (new Date(seance?.data().date.toDate().toJSON())),
                                  "MMM yyyy",
                                  { locale: fr }
                                )}
                              </Typography>
                              <Box sx={{

                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',

                              }}>
                                <Avatar sx={{ bgcolor: "#5E17EB", }}>
                                  <Typography
                                    textAlign="center"
                                  >
                                    {format(
                                      (new Date(seance?.data().date.toDate().toJSON())),
                                      "dd",
                                      { locale: fr }
                                    )}
                                  </Typography></Avatar>
                              </Box>


                              <Typography
                                textAlign="center"
                              >
                                {seance ? format(
                                  (new Date(seance?.data().date.toDate().toJSON())),
                                  "HH:mm",
                                  { locale: fr }
                                ) : null}
                              </Typography>
                            </Stack>
                          </Box>
                        ) : (
                          <Box
                            padding={2}
                            sx={{
                              borderRadius: "7px",
                              border: "2px solid #f2f2f2",
                              cursor: "pointer",
                              "&:hover": {
                                borderColor: "#5E17EB",
                              },
                            }}
                            onClick={() => changeSeance(index)}
                          >
                            <Stack>
                              <Typography
                                textAlign="center"
                                sx={{ paddingBottom: 1 }}
                              >
                                {format(
                                  (new Date(seance?.data().date.toDate().toJSON())),
                                  "EEEE",
                                  { locale: fr }
                                )}
                              </Typography>
                              <Typography
                                textAlign="center"
                              >
                                {format(
                                  (new Date(seance?.data().date.toDate().toJSON())),
                                  "MMM yyyy",
                                  { locale: fr }
                                )}
                              </Typography>
                              <Box sx={{

                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',

                              }}>
                                <Avatar sx={{ color: "#5E17EB", bgcolor: '#f2f2f2' }}>
                                  <Typography
                                    textAlign="center"
                                  >
                                    {format(
                                      (new Date(seance?.data().date.toDate().toJSON())),
                                      "dd",
                                      { locale: fr }
                                    )}
                                  </Typography></Avatar>
                              </Box>
                              <Typography
                                textAlign="center"
                              >
                                {format(
                                 (new Date(seance?.data().date.toDate().toJSON())),
                                  "HH:mm",
                                  { locale: fr }
                                )}
                              </Typography>
                            </Stack>
                          </Box>
                        )
                      )}
                    </Stack>
                  </Grid>
                )}

                <Grid item xs={12} md={7}>
                  {mdUp && (
                    <>
                      <Typography
                        paddingBottom={2}
                        variant="h5"
                        fontWeight={600}
                      >
                        Choisissez vos billets { }
                      </Typography>
                      {
                        // event passé
                        new Date(handleTimestamp(event.dateFin)) < today1 ?
                          <Typography
                            variant="h7"
                            fontWeight={600}
                            color="#1F1F1F"
                          >
                            L'evenement <strong>{event.nomEvent}</strong> est terminé
                          </Typography>
                          : new Date(handleTimestamp(event.dateFin)) > today1 ?
                            <Grid container spacing={3}>
                              {tickets.length === 0 && loading ? (
                                <>
                                  <Grid item xs={12} md={12}>
                                    <Skeleton variant="rectangular" height={150} />
                                  </Grid>
                                  <Grid item xs={12} md={12}>
                                    <Skeleton variant="rectangular" height={150} />
                                  </Grid>
                                </>
                              ) : tickets.length === 0 && !loading ? (
                                <Grid xs={12} md={12}>
                                  
                                  <Stack
                                    paddingTop={4}
                                    paddingX={4}
                                    spacing={3}
                                    direction="row"
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                  >
                                    <Box sx={{ bgcolor: '#f2f2f2', borderRadius: '10px', padding: 2}}>
                                    <Typography
                                      fontSize={17}
                                      fontWeight={600}
                                      color="#1F1F1F"
                                    >
                                      Aucun billet mise à disposition par {org.nomOrg}{" "}
                                      pour l'instant, mais vous pourriez être
                                      intéressé d'autres événements.
                                    </Typography>
                                    </Box>
                                    
                                  </Stack>
                                </Grid>
                              ) : (
                                tickets?.map((ticket, index) => (
                                  <Grid item xs={12} md={12}>
                                    <Box
                                      sx={{
                                        padding: 4,
                                        bgcolor: "#F3EDFD",
                                        color: "#1F1F1F",
                                        borderRadius: "10px",
                                      }}
                                    >
                                      <Grid container justifyContent="space-between">
                                        <Grid>
                                          <Typography fontSize={17} fontWeight={600}>
                                            {ticket?.data()?.ticket_name}
                                          </Typography>
                                          <Typography fontSize={17}>
                                            {ticket?.data().price === 0
                                              ? "Gratuit"
                                              : ticket?.data().price + " CFA"}
                                          </Typography>
                                        </Grid>
                                        {seances.length !== 0 ? (
                                          <Grid>
                                            {seances[seanceActive].data().billets[
                                              seances[seanceActive]
                                                .data()
                                                .billets.findIndex(
                                                  ({ id }) => id === ticket.id
                                                )
                                            ].quota ===
                                              seances[seanceActive].data().billets[
                                                seances[seanceActive]
                                                  .data()
                                                  .billets.findIndex(
                                                    ({ id }) => id === ticket.id
                                                  )
                                              ].sell &&
                                              seances[seanceActive].data().billets[
                                                seances[seanceActive]
                                                  .data()
                                                  .billets.findIndex(
                                                    ({ id }) => id === ticket.id
                                                  )
                                              ].sell !== 0 ? (
                                              <Box
                                                component="button"
                                                padding={0.5}
                                                sx={{
                                                  borderRadius: "5px",
                                                  fontSize: 11,
                                                  backgroundColor: "#FBE79D",
                                                  border: "1px dashed #FBE79D",
                                                }}
                                              >
                                                épuisé
                                              </Box>
                                            ) : (
                                              <Stack direction="row" spacing={1}>
                                                {quantite[index] === 0 ? (
                                                  <IconButton
                                                    size="small"
                                                    disabled
                                                    aria-label="fingerprint"
                                                    sx={{
                                                      bgcolor: "#5E17EB",
                                                      color: "white",
                                                    }}
                                                  >
                                                    <Iconify icon="ic:baseline-minus" />
                                                  </IconButton>
                                                ) : (
                                                  <IconButton
                                                    onClick={() =>
                                                      removeTicket(index)
                                                    }
                                                    size="small"
                                                    aria-label="fingerprint"
                                                    sx={{
                                                      bgcolor: "#5E17EB",
                                                      color: "white",
                                                    }}
                                                  >
                                                    <Iconify icon="ic:baseline-minus" />
                                                  </IconButton>
                                                )}
                                                <Typography fontSize={17}>
                                                  {quantite[index]}
                                                </Typography>
                                                <IconButton
                                                  onClick={() => addTicket(index)}
                                                  size="small"
                                                  aria-label="fingerprint"
                                                  sx={{
                                                    bgcolor: "#5E17EB",
                                                    color: "white",
                                                  }}
                                                >
                                                  <Iconify icon="material-symbols:add" />
                                                </IconButton>
                                              </Stack>
                                            )}
                                          </Grid>
                                        ) : (
                                          <Grid>
                                            {ticket.data().quota >
                                              ticket.data().sell + (ticket.data().encours || 0) ? (
                                              <Stack direction="row" spacing={1}>
                                                {quantite[index] === 0 ? (
                                                  <IconButton
                                                    size="small"
                                                    disabled
                                                    aria-label="fingerprint"
                                                    sx={{
                                                      bgcolor: "#5E17EB",
                                                      color: "white",
                                                    }}
                                                  >
                                                    <Iconify icon="ic:baseline-minus" />
                                                  </IconButton>
                                                ) : (
                                                  <IconButton
                                                    onClick={() =>
                                                      removeTicket(index)
                                                    }
                                                    size="small"
                                                    aria-label="fingerprint"
                                                    sx={{
                                                      bgcolor: "#5E17EB",
                                                      color: "white",
                                                    }}
                                                  >
                                                    <Iconify icon="ic:baseline-minus" />
                                                  </IconButton>
                                                )}
                                                <Typography fontSize={17}>
                                                  {quantite[index]}
                                                </Typography>
                                                <IconButton
                                                  onClick={() => addTicket(index)}
                                                  size="small"
                                                  aria-label="fingerprint"
                                                  sx={{
                                                    bgcolor: "#5E17EB",
                                                    color: "white",
                                                  }}
                                                >
                                                  <Iconify icon="material-symbols:add" />
                                                </IconButton>
                                              </Stack>
                                            ) : (

                                              <Box
                                                component="button"
                                                padding={0.5}
                                                sx={{
                                                  borderRadius: "5px",
                                                  fontSize: 11,
                                                  backgroundColor: "#FBE79D",
                                                  border: "1px dashed #FBE79D",
                                                }}
                                              >
                                                épuisé
                                              </Box>
                                            )}
                                          </Grid>
                                        )}
                                      </Grid>
                                      {ticket.data().description &&
                                        ticket.data().description !== "" && (
                                          <Grid>
                                            <Box>
                                              <Accordion
                                                elevation={0}
                                                sx={{ backgroundColor: "#F3EDFD" }}
                                              >
                                                <AccordionSummary
                                                  expandIcon={
                                                    <Iconify icon="ep:arrow-down-bold" />
                                                  }
                                                  aria-controls="panel1a-content"
                                                  id="panel1a-header"
                                                >
                                                  <Typography
                                                    fontSize={17}
                                                    sx={{ color: "#1F1F1F" }}
                                                  >
                                                    Détail sur le billet
                                                  </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails
                                                  sx={{
                                                    backgroundColor: "white",
                                                    borderRadius: "7px",
                                                  }}
                                                >
                                                  <Typography variant="body2">
                                                    {ticket.data().description}
                                                  </Typography>
                                                </AccordionDetails>
                                              </Accordion>
                                            </Box>
                                          </Grid>
                                        )}
                                    </Box>
                                  </Grid>
                                ))
                              )}
                            </Grid>
                            : null
                      }

                    </>
                  )}
                  <Stack paddingTop={mdUp ? 10 : 2}
                    paddingBottom={2} direction={"row"} justifyContent={'space-between'} alignItems={'center'}>
                    <Typography

                      variant="h5"
                      fontWeight={600}
                    >
                      Description
                    </Typography>
                    <Typography
                      variant="caption"
                      sx={{ color: '#5E17EB' }}
                    >
                      <strong></strong>
                    </Typography>
                  </Stack>

                  <Box sx={{ bgcolor: '#f2f2f2', borderRadius: '10px', padding: 2 }}>
                    {desc !== "" ? (
                      <Typography fontSize={17} color="#1F1F1F">
                        <ReactQuill
                          theme="bubble"
                          readOnly={true}
                          //modules={modules}
                          formats={formats}
                          value={desc}
                        //onChange={handleChangeQuill}
                        />
                      </Typography>
                    ) : (
                      <Typography fontSize={17} color="#1F1F1F">
                        Aucune description renseignée par l'organisateur !
                      </Typography>
                    )}
                  </Box>

                  <Box>
                    <Typography
                      paddingTop={mdUp ? 10 : 5}
                      paddingBottom={2}
                      variant="h5"
                      fontWeight={600}
                    >
                      Location
                    </Typography>
                    {
                      event?.locationType === 1 ?

                        <Box sx={{ bgcolor: '#f2f2f2', borderRadius: '10px', padding: 2 }}>

                          <Typography fontSize={17} color="#1F1F1F">
                            Evenement en ligne un lien de participation vous sera mis a disposition a pres l'achat d'un billet pour acceder a l'evenement
                          </Typography>

                        </Box>

                        :
                        <>
                          <Typography fontSize={17} color="#1F1F1F">
                            {event.localisationDes}
                          </Typography>
                          <Typography fontSize={17} color="#1F1F1F">
                            {event.infoLieu}
                          </Typography>
                          <Box sx={{ width: "100%" }}>
                            <Maps centerC={event.localisation} />
                          </Box>
                          <Stack marginTop={2} spacing={1} sx={{ bgcolor: '#f2f2f2', borderRadius: '10px', }} padding={2}>
                            <Typography fontSize={17} textAlign={'center'}>Comment s'y rendre ?</Typography>

                            <CustomButton type={'primaire'} buttonText={'Obtenir l\'itineraire'} />
                          </Stack>
                        </>
                    }

                  </Box>
                  <Typography
                    paddingTop={mdUp ? 10 : 5}
                    paddingBottom={2}
                    variant="h5"
                    fontWeight={600}
                  >
                    Organisateur
                  </Typography>
                  <Box
                    elevation={3}
                    sx={{
                      padding: 5,
                      borderRadius: '10px',
                      bgcolor: '#f2f2f2'
                    }}
                  >
                    <Stack spacing={2}>
                      <Typography fontSize={17} align="center">Organisé par</Typography>
                      <Box display="flex" justifyContent="center">
                        <Avatar src={org.idVisuel} sx={{ width: 125, height: 125 }}></Avatar>
                      </Box>
                      <Typography align="center">{org.nomOrg}</Typography>
                      <Grid container spacing={2} >
                        <Grid item xs={12} sm={6}>
                          <Button
                            size="large"
                            fullWidth
                            sx={{
                              textTransform: "initial",
                              border: "2px solid transparent",
                              color: "white",
                              backgroundColor: "black",
                              borderRadius: "7px",
                              "&:hover": {
                                backgroundColor: "white",
                                color: "black",
                                borderColor: "black",
                              },
                            }}
                            variant="contained"
                            onClick={handleOpenContact}
                          >
                            Contactez
                          </Button>
                        </Grid>
                        {/** contact modal */}
                        <Modal
                          open={openContact}
                          onClose={handleCloseContact}
                          aria-labelledby="parent-modal-title"
                          aria-describedby="parent-modal-description"
                        >
                          <Contact />
                        </Modal>
                        <Grid item xs={12} sm={6}>
                          <Button
                            size="large"
                            fullWidth
                            sx={{
                              textTransform: "initial",
                              border: "2px solid transparent",
                              color: "white",
                              backgroundColor: "#5E17EB",
                              borderRadius: "7px",
                              "&:hover": {
                                backgroundColor: "white",
                                color: "#5E17EB",
                                borderColor: "#5E17EB",
                              },
                            }}
                            variant="contained"
                            onClick={() => {
                              window.open(`/fr/organisateur/${event.orgId}`);
                            }}
                          >
                            Profil organisateur
                          </Button>
                        </Grid>
                      </Grid>
                    </Stack>

                  </Box>

                </Grid>

                {/** bouton utilisé sur mobile */}
                {!mdUp && (
                  <Card
                    elevation={5}
                    sx={{
                      position: "fixed",
                      bottom: 0,
                      left: 0,
                      right: 0,
                      paddingX: 5,
                      paddingY: 2,
                    }}
                  >
                    <Typography fontSize={17} align="center" paddingBottom={2}>
                      {event && event.minPrice !== 0 ? `à partir de ${event.minPrice} CFA` : 'Cet event propose des tickets gratuits'}
                    </Typography>
                    <CustomButton fullWidth type='primaire' buttonText={'Reservé maintenant'} onClick={handleOpen} />
                  </Card>
                )}
                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <Stack padding={1} direction="row">
                      <IconButton onClick={handleClose}>
                        <Iconify
                          inline={true}
                          icon="material-symbols:arrow-back-rounded"
                          sx={{ width: 24, height: 24, color: "#1F1F1F" }}
                        />
                      </IconButton>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          width: "100%",
                        }}
                      >
                        <Typography
                          variant="h6"
                          textAlign="center"
                        >
                          {event.nomEvent}
                        </Typography>
                      </Box>
                    </Stack>
                    <Divider />
                    <ReserMobile eventID={eventID} />
                  </Box>
                </Modal>

                {ticketsList.length !== 0 && smUp ? (
                  <Grid
                    sx={{ position: "", top: "8%", left: "60%" }}
                    item
                    xs={12}
                    md={5}
                  >
                    <Card
                      sx={{
                        padding: 3,
                        border: "2px solid #F3EDFD ",
                        borderRadius: "7px",
                      }}
                    >
                      <Typography variant="h5" fontWeight={600}>
                        x{ticketTotals}
                      </Typography>
                      <Typography fontSize={17}>
                        Billets selectionné(s)
                      </Typography>
                      <Stack marginY={2} spacing={1}>
                        {ticketsList.map((billet, index) => (
                          <Box bgcolor="#f2f2f2" borderRadius={'10px'} padding={2}>
                            <Typography>{billet.nom}</Typography>
                            <Stack
                              direction="row"
                              justifyContent="space-between"
                            >
                              <Typography fontSize={17}>
                                {billet.quantite}
                              </Typography>
                              <Typography fontSize={17}>
                                {billet.prix === 0
                                  ? "Gratuit"
                                  : billet.prix + " CFA"}
                              </Typography>
                            </Stack>
                          </Box>
                        ))}
                      </Stack>

                      <Divider />
                      <Stack
                        paddingY={2}
                        direction="column"
                        justifyContent="center"
                      >
                        {discount && (
                          <>
                            <Stack
                              direction="row"
                              justifyContent="space-between"
                            >
                              <Typography fontSize={16} fontWeight={500}>
                                Total hors réduction :
                              </Typography>
                              <Typography fontSize={16} fontWeight={500}>
                                {montant} CFA{" "}
                              </Typography>
                            </Stack>
                            <Stack
                              direction="row"
                              justifyContent="space-between"
                            >
                              <Typography fontSize={14} fontWeight={500}>
                                Réduction :
                              </Typography>
                              <Typography fontSize={14} fontWeight={500}>
                                {discount.value} CFA{" "}
                              </Typography>
                            </Stack>
                          </>
                        )}
                        <Stack direction="row" justifyContent="space-between">
                          <Typography fontSize={17} fontWeight={600}>
                            Total :
                          </Typography>
                          <Typography fontSize={17} fontWeight={600}>
                            {discount ? montant - discount.value : montant} CFA{" "}
                          </Typography>
                        </Stack>
                      </Stack>

                      <CustomButton fullWidth buttonText={'Reserver'} type={'primaire'} onClick={() => {
                        setOpenReservation(true);
                      }} />
                      <Modal
                        open={openReservation}
                        aria-labelledby="parent-modal-title"
                        aria-describedby="parent-modal-description"
                      >
                        <ReserDesktop
                          event={event}
                          ticketsList={ticketsList}
                          montant={montant}
                          reduction={discount}
                          seance={
                            seances.length !== 0
                              ? seances[seanceActive].id
                              : null
                          }
                        />
                      </Modal>
                    </Card>
                  </Grid>
                ) : null}

              </Grid>
              <Stack display="row" alignContent="center" alignItems="center">
                <Stack>
                  <Iconify />
                </Stack>
              </Stack>
            </>
          </Container>

          <Footer />
        </>
      ) : (
        <CircularProgress sx={{ color: "#5E17EB" }} />
      )}
    </>
  );
}
