import * as React from 'react';
import { useLocation, useParams } from 'react-router-dom';


export default function AffTracker() {
    const { eventID } = useParams()

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const fb = queryParams.get("fb-ctt");
    function getCookie(name) {
        const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
        if (match) return match[2];
        return null;
    }

    // Fonction pour définir un cookie avec une expiration
    function setCookie(name, value, days) {
        const d = new Date();
        d.setTime(d.getTime() + (days * 24 * 60 * 60 * 1000)); // durée en jours
        const expires = "expires=" + d.toUTCString();
        document.cookie = `${name}=${value};${expires};path=/`;
    }

    function trackAffiliateClick(affiliateId) {
        fetch(`https://us-central1-event-86cbf.cloudfunctions.net/trackAffiliateClick?affiliateId=${affiliateId}&eventId=${eventID}`)
    }

    React.useEffect(() => {
        (async () => {
            try {
                console.log('aff tracker')
                const urlParams = new URLSearchParams(window.location.search);
                const affiliateId = urlParams.get('aff');
                console.log(eventID)
                if (affiliateId) {
                    console.log(affiliateId);
                    const cookieName = `affId-${eventID}`;
                    const existingAffiliate = getCookie(cookieName);
                    console.log(existingAffiliate)

                    if (!existingAffiliate) {
                        // Si l'ID n'existe pas dans les cookies, on le définit et on enregistre le clic
                        setCookie(cookieName, affiliateId, 7); // Stocke l'ID pour 7 jours
                        trackAffiliateClick(affiliateId); // Appelle la fonction pour tracker le clic
                    }
                }
                console.log('fin aff tracker')
            } catch (error) {
                console.log(error);
            }

        })();
    }, []);

    return (
        <div>
        </div>
    );
}